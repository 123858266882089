import Axios from 'axios'
import React, { Component } from 'react'
import Header from './Header'
import SideNav from './SideNav'

class BreakPointInstructions extends Component {
    state = {
        step: "",
        breakPoint: null,
        instructionBox: "",
        error: "",
        success: ""
    }

    onChangeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: "",
            success: ""
        })

        if (e.target.name === "step" && e.target.value) {
            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/instructions", {
                params: { step: e.target.value }
            }).then(result => {
                this.setState({
                    instructionBox: result.data.breakPointInstructions,
                    breakPoint: result.data.breakPoint,
                })
            }).catch(error => {
                console.log(error);
            })
        }
    }

    onSubmitHandler = e => {
        e.preventDefault();
        this.setState({
            error: "",
            success: ""
        })
        Axios.post(process.env.REACT_APP_BASE_URL + "/admin/steps/instructions/break-point", {
            step: this.state.step,
            instructionBox: this.state.instructionBox,
            breakPoint: this.state.breakPoint,
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            if (result.data.error) {
                this.setState({
                    error: result.data.error,
                    success: ""
                })
            } else {
                this.setState({
                    success: result.data.message,
                    error: ""
                })
            }

        }).catch(error => {
            console.log(error);
        })

    }

    render() {
        return (
            <div>
                <Header />
                <SideNav />
                <div className="instructionPage">
                    <div className="text-dark text-center mt-5">
                        <h3>Add / Edit Break Point Instructions</h3>
                    </div>
                    <div className="text-danger text-center">
                        <span>{this.state.error}</span>
                    </div>
                    <div className="text-success text-center">
                        <span>{this.state.success}</span>
                    </div>
                    <form onSubmit={this.onSubmitHandler}>
                        <div className="row p-4 m-1 m-auto">
                            <div className="col-2">
                                <div className="form-group">
                                    <select className="form-control bg-info text-light"
                                        onChange={this.onChangeHandler}
                                        value={this.state.step}
                                        name="step">
                                        <option value="">-Choose Step-</option>
                                        <option value="1A">Step 1A</option>
                                        <option value="1B">Step 1B</option>
                                        <option value="2">Step 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="form-group mr-5">
                                    <textarea className="form-control bg-light"
                                        onChange={this.onChangeHandler}
                                        value={this.state.instructionBox}
                                        rows="15"
                                        name="instructionBox"
                                        placeholder="Start Typing here..."
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-2">
                            </div>
                            <div className="col-10">
                                <div className="form-group mr-5">
                                    <label className="float-left">Break Point After</label>
                                    <input
                                        name="breakPoint"
                                        onChange={this.onChangeHandler}
                                        value={this.state.breakPoint}
                                        type="number"
                                        className="form-control w-100"
                                        placeholder="Break Point After"
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="form-group text-center pt-5 m-auto w-50">
                                <button className="btn btn-info w-25" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default BreakPointInstructions