import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";

export default class Uploads extends Component {
  state = {
    images: [],
    imagesError: "",
    imagesSuccess: "",
    words: "",
    wordsError: "",
    wordsSuccess: "",
    selectImageCategory: [],
    selectWordCategory: [],
    imageCategory: "",
    imageType: "",
    wordCategory: "",
    wordType: "",
  };

  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-image-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              if (result.data) {
                this.setState({
                  selectImageCategory: result.data,
                });
              }
              if (result.data.error) {
                console.log(result.data.error);
                this.setState({
                  imagesError: result.data.error,
                });
              }
            })
            .catch((error) => console.log(error));

          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-word-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              if (result.data) {
                this.setState({
                  selectWordCategory: result.data,
                });
              }
              if (result.data.error) {
                console.log(result.data.error);
                this.setState({
                  wordsError: result.data.error,
                });
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  // Image Change

  onImageChange = (e) => {
    this.setState({
      images: e.target.files,
    });
  };

  // Word Change

  onWordChange = (e) => {
    this.setState({
      words: e.target.files,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Uploading Images

  onImageSubmit = (e) => {
    e.preventDefault();
    this.setState({
      imagesSuccess: "Uploading..",
      imagesError: "",
    });
    const data = new FormData();
    data.append("imageCategory", this.state.imageCategory);
    data.append("imageType", this.state.imageType);

    const { images } = this.state;
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i]);
      }
    }

    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/upload-images-general", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          this.setState({
            imagesError: res.data.error,
            imagesSuccess: "",
          });
        }
        if (res.data.message) {
          this.setState({
            imagesSuccess: res.data.message,
            imagesError: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  // Uploading Words

  onWordSubmit = (e) => {
    e.preventDefault();
    this.setState({
      wordsSuccess: "Uploading..",
      wordsError: "",
    });
    const data = new FormData();
    data.append("words", this.state.words[0]);
    data.append("wordCategory", this.state.wordCategory);
    data.append("wordType", this.state.wordType);
    // console.log(this.state);
    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/upload-words-general", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            wordsError: res.data.error,
            wordsSuccess: "",
          });
        }
        if (res.data.message) {
          this.setState({
            wordsSuccess: res.data.message,
            wordsError: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="container text-center uploadForms m-auto">
          <div className="row mt-5">
            <div className="col-md-6 col-lg-6 column1">
              <h4>Upload Images</h4>
              <form onSubmit={this.onImageSubmit.bind(this)}>
                <div>
                  <i className="fa fa-image" id="img-icon"></i>

                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      name="images"
                      onChange={this.onImageChange.bind(this)}
                      multiple
                    />
                  </div>
                  <div className="form-group">
                    <select
                      name="imageCategory"
                      onChange={this.onChange.bind(this)}
                      value={this.state.imageCategory}
                    >
                      <option value="">Select Images Category</option>
                      {this.state.selectImageCategory.map((cat) => (
                        <option value={cat} key={uuid()}>
                          {cat}
                        </option>
                      ))}
                    </select>

                    <select
                      name="imageType"
                      defaultValue="default"
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="default">Select Type</option>
                      <option value="A">Active</option>
                      <option value="B">Sham</option>
                    </select>
                  </div>
                </div>
                <button className="btn btn-info" type="submit">
                  Upload Images
                </button>
                <br />
                <span className="text-danger">
                  {this.state.imagesError ? this.state.imagesError : ""}
                </span>
                <span className="text-success">
                  {this.state.imagesSuccess ? this.state.imagesSuccess : ""}
                </span>
              </form>
            </div>
            <div className="col-md-6 col-lg-6 column2">
              <h4>Upload Words</h4>

              <form
                encType="multipart/form-data"
                onSubmit={this.onWordSubmit.bind(this)}
              >
                <div>
                  <i className="fa fa-file" id="file-icon"></i>
                  <br />
                  <br />
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      name="words"
                      onChange={this.onWordChange.bind(this)}
                    />
                    <br />
                    <div className="form-group">
                      <select
                        name="wordCategory"
                        onChange={this.onChange.bind(this)}
                        value={this.state.wordCategory}
                      >
                        <option value="">Select Words Category</option>
                        {this.state.selectWordCategory.map((cat) => (
                          <option value={cat} key={uuid()}>
                            {cat}
                          </option>
                        ))}
                      </select>
                      <select
                        name="wordType"
                        defaultValue="default"
                        onChange={this.onChange.bind(this)}
                      >
                        <option value="default" disabled>
                          Select Type
                        </option>
                        <option value="A">Active</option>
                        <option value="B">Sham</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button className="btn btn-info px-3" type="submit">
                  Upload Words
                </button>
                <br />
                <span className="text-danger">
                  {this.state.wordsError ? this.state.wordsError : ""}
                </span>
                <span className="text-success">
                  {this.state.wordsSuccess ? this.state.wordsSuccess : ""}
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
