import React, { Component } from 'react'
import Header from './Header'
import SideNav from './SideNav'
import { v1 as uuid } from "uuid";
import Axios from 'axios';

class DisplayUserWords extends Component {
    state = {
        errors: "",
        success: "",
        username: "",
        usernames: [],
        words: []
    }
    componentDidMount() {

        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.props.history.push("/admin");
                }
                else {
                    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/all-username", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                        },
                    }).then((result) => {
                        if (result.data.message) {
                            this.setState({
                                usernames: result.data.message,
                            });
                        }
                    }).catch((error) => console.log(error));
                }
            })
            .catch((error) => {
                return console.log(error);
            });
    }

    onChangeHandler = e => {
        this.setState({
            words: [],
            username: e.currentTarget.value
        })
        if (e.currentTarget.value) {
            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/user-words/" + e.target.value, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                }
            }).then(result => {
                if (result.data.error) {
                    this.setState({
                        errors: result.data.error,
                        success: ""
                    })
                }
                if (result.data.words) {
                    this.setState({
                        words: result.data.words,
                        errors: "",
                        success: ""
                    })
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
    onDeleteHandler = e => {
        e.persist()
        let x = e.currentTarget.value;

        Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/delete/user-word`, {
            params: {
                username: this.state.username,
                word: e.currentTarget.value
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            if (result.data.message) {

                this.setState({
                    words: this.state.words.filter(word => word !== x),
                    errors: "",
                    success: result.data.message
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }
    render() {
        let wordsContainer = this.state.words.map((word) => (
            <tr key={uuid()}>
                <td className="p-0 m-0">{word}</td>
                <td className="p-0 m-0">
                    <button
                        onClick={this.onDeleteHandler}
                        value={word}
                        className="btn btn-outline-danger py-0 mx-3 deleteWord"
                    >
                        <i className="fa fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        ));
        return (
            <div className="words">
                <Header />
                <SideNav />
                <div className="wordsPage text-center">
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    name="username"
                                    onChange={this.onChangeHandler}
                                    value={this.state.username}
                                >
                                    <option value="">
                                        Select Username
                                    </option>
                                    {this.state.usernames.map(name => (
                                        <option key={uuid()} value={name}>{name}</option>
                                    ))}
                                </select>

                                <span className="text-danger">{this.state.errors}</span>
                            </div>
                        </form>
                    </div>
                    <div className="text-center text-primary">
                        <span>{this.state.success}</span>
                    </div>
                    <div className="container w-50 bg-light">
                        <table className="w-50 m-auto allCatTable table table-hover table-light">
                            <tbody>{wordsContainer}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default DisplayUserWords