import React from "react";
import { Link } from "react-router-dom";
export default function UserHeader() {
  const onClickLogout = (e) => {
    localStorage.removeItem("user-access-token");
  };
  return (
    <div className="container-fluid bg-dark header">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link className="navbar-brand" to="/step1">
          PittsApp
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link
              className="nav-item nav-link active logoutLink"
              to="/game1"
              onClick={onClickLogout.bind(this)}
            >
              <i className="fas fa-sign-out-alt"></i> Logout
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}
