import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";

class Category extends Component {
  state = {
    newCat: "",
    categoryType: "",
    errorMessage: "",
    successMessage: "",
    result: [],
  };
  componentDidMount() {
    //   Check Authorization
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  viewAllCategories = (e) => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/categories/all", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        if (result.data.categories) {
          this.setState({
            result: result.data.categories,
          });
        }
      })
      .catch((error) => { });
  };

  onChangeHandler = (e) => {
    if (e.target.name === "newCat") {
      e.target.value = e.target.value.toUpperCase();
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    Axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/categories/new-category",
      this.state,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      }
    )
      .then((result) => {
        if (result.data.error) {
          this.setState({
            errorMessage: result.data.error,
            successMessage: "",
          });
        }
        if (result.data.message) {
          this.setState({
            successMessage: result.data.message,
            errorMessage: "",
            newCat: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onDeleteHandler = (e) => {
    Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/categories/delete", {
      params: { catName: e.currentTarget.value },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        if (result.data.message) {
          this.setState({
            successMessage: result.data.message,
            errorMessage: "",
          });
        }
      })
      .catch((error) => console.log(error));
    this.setState({
      result: this.state.result.filter((cat) => cat !== e.currentTarget.value),
    });
  };
  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="categoriesPage">
          <h3 className="text-center">Categories</h3>
          <br />
          <div className="w-25 m-auto text-center">
            <span className="text-danger">
              {this.state.errorMessage ? this.state.errorMessage : ""}
            </span>
            <span className="text-success">
              {this.state.successMessage ? this.state.successMessage : ""}
            </span>
            <br />
            <br />
            <form onSubmit={this.onSubmitHandler.bind(this)}>
              <div className="form-group">
                <input
                  type="text"
                  className=" form-control "
                  name="newCat"
                  value={this.state.newCat}
                  onChange={this.onChangeHandler.bind(this)}
                  placeholder="Enter Category"
                />
              </div>
              <div className="form-group">
                <select
                  className=" form-control"
                  name="categoryType"
                  onChange={this.onChangeHandler.bind(this)}
                  defaultValue="default"
                >
                  <option value="default" disabled>
                    Select Type
                  </option>
                  <option value="image">Image</option>
                  <option value="word">Word</option>
                </select>
              </div>
              <button className="btn btn-info w-100" type="submit">
                Add Category
              </button>
            </form>
          </div>
          <div className="allCatsWrapper text-center">
            <button
              className="btn btn-outline-info"
              onClick={this.viewAllCategories.bind(this)}
            >
              View All Categories
            </button>
            <br />
            <br />
            <br />

            <div className="allCats bg-light" id="add-cats">
              <table
                className="w-50 m-auto allCatTable table table-bordered table-hover table-light"
                id="all-cat-table"
              >
                <tbody>
                  {this.state.result.map((cat) => (
                    <tr key={uuid()} className="">
                      <td>{cat}</td>
                      <td>
                        <button
                          onClick={this.onDeleteHandler.bind(this)}
                          value={cat}
                          className="btn btn-outline-danger py-0 mx-3 deleteWord"
                        >
                          <i className="fa fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Category;
