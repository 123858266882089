import Axios from "axios";
import React, { Component } from "react";

export default class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMsg: "",
    };
  }
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const onSubmitHandler = (e) => {
      e.preventDefault();
      Axios.post(process.env.REACT_APP_BASE_URL + "/admin/login", this.state)
        .then((result) => {
          if (result.data.message) {
            localStorage.setItem("admin-access-token", result.data.accessToken);
            this.props.history.push("/admin/admin-dashboard");
          } else {
            this.setState({
              errorMsg: result.data.error,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <div>
        <div className="container-fluid p-3 px-5 bg-dark adminLoginHeader">
          <h1 className="text-warning">PittsApp</h1>
          <h1 className="text-info">Admin Login</h1>
        </div>
        <form
          className="m-auto pt-5 mt-5 adminLoginForm"
          onSubmit={onSubmitHandler.bind(this)}
        >
          <div className="container text-center text-danger">
            <span>
              {this.state.errorMsg ? `Error : ${this.state.errorMsg}` : ""}
            </span>
          </div>
          <div className="form-group my-5">
            <input
              className="form-control"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.onChangeHandler.bind(this)}
              placeholder="Enter Email"
            />
          </div>

          <div className="form-group my-5">
            <input
              className="form-control"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.onChangeHandler.bind(this)}
              placeholder="Enter Password"
            />
          </div>
          <div className="form-group my-5">
            <button className="btn btn-outline-dark float-right" type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    );
  }
}
