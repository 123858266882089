import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";
import { jwtDecode } from "jwt-decode";
import debounce from "lodash.debounce";
import Swal from 'sweetalert2'

class AllUsers extends Component {
  state = {
    error: "",
    success: "",
    users: [],
    search: null,
    isSuperAdmin: false,
    isLoading: false
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          this.setState({
            isSuperAdmin: jwtDecode(localStorage.getItem("admin-access-token")).role === "SUPER_ADMIN"
          });
          this.getUsers(this.state.search); 
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  getUsers = (search = null) => {
    this.setState({
      isLoading: true
    })
    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/all-users", {
      params: {
        search
      },
      headers: {
        'Cache-Control': 'no-cache',
        Authorization:
          "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        console.log(result.data);
        this.setState({
          users: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }).finally(() =>{
        this.setState({
          isLoading: false
        })
      })
  }

  onSearchChange = (e) => {
    this.setState({
      "search": e.target.value,
    }, () => {
      this.handleSearch()
    });

  };

  handleSearch = debounce(async () => {
    this.getUsers(this.state.search); 
  }, 500);

  render() {
    let table = !this.state.isLoading ? (
      <div className="container-fluid">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="text-info">Index</th>
              <th className="text-info">Username</th>
              <th className="text-info">User ID</th>
              <th className="text-info">User Cat</th>
              <th className="text-info">User Version</th>
              <th className="text-info">Last Logged In At [Game 1]</th>
              <th className="text-info">Last Logged In At [Game 2]</th>

              <th className="text-info">Email</th>
              {/* <th className="text-info">Game 1 <br /> Latency</th>
              <th className="text-info">Game 1 <br /> Timestamps</th>
              <th className="text-info">Step 1 score
                <br />
                <span># Correct |# Incorrect</span>
              </th>
              <th className="text-info">Step 3 score
                <br />
                # Correct |# Incorrect
              </th> */}
              <th className="text-info">
                Actions
              </th>
              <th className="text-info">Game1 Export</th>
              <th className="text-info">Game2 Export</th>

            </tr>
          </thead>
          <tbody>
            {this.state.users.map((user, index) => (
              <tr key={uuid()}>
                <th scope="row">{index + 1}</th>
                <td>{user.userName}</td>
                <td>{user._id}</td>
                <td>{user.userType === "A" ? "Active" : "Sham"}</td>
                <td>{user.version ? user.version : "Nil"}</td>
                <td>{user.lastLoggedInAtGame1 ? new Date(user.lastLoggedInAtGame1).toLocaleString() : "-"}</td>
                <td>{user.lastLoggedInAtGame2 ? new Date(user.lastLoggedInAtGame2).toLocaleString() : "-"}</td>
                <td>{user.email ? user.email : "Nil"}</td>

                <td>
                  <div>
                    <button className="btn btn-warning mx-3" value={user._id} onClick={this.suspendUser}>
                      Suspend&nbsp;<i className="fa fa-user-slash"></i>
                    </button>
                  </div>
                  <div className="mt-2">
                    { !user.isDeleted ? 
                        <button className="btn btn-danger mx-3" value={user._id} onClick={this.deleteUser}>
                          Delete&nbsp;
                          <i className="fa fa-trash-alt"></i>
                        </button>
                      : user.isDeleted && this.state.isSuperAdmin ? 
                        <button className="btn btn-warning mx-3" value={user._id} onClick={this.restoreDeleteionOfUser}>
                          Restore&nbsp;
                          <i className="fa fa-user-slash"></i>
                        </button>
                      : null
                    }
                  </div>
                  <div className="mt-2">
                    {
                      this.state.isSuperAdmin && 
                      <button className="btn btn-danger mx-3" value={user._id} onClick={this.hardDeleteUser}>
                        Hard Delete&nbsp;
                        <i className="fa fa-trash-alt"></i>
                      </button>
                    }
                  </div>
                </td>
                <td>
                  <button className="btn btn-outline-dark px-4" exportType="csv"  value={user._id} onClick={this.exportGame1Results}>
                    Csv&nbsp;
                    <i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                  </button>
                  <button className="btn btn-outline-dark px-4 ml-2" exportType="json"  value={user._id} onClick={this.exportGame1Results}>
                    Json&nbsp;
                    <i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                  </button>
                </td>
                <td>
                  <button className="btn btn-outline-dark px-4" exportType="csv"  value={user._id} onClick={this.exportGame2Results}>
                    Csv&nbsp;
                    <i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                  </button>
                  <button className="btn btn-outline-dark px-4 ml-2" exportType="json"  value={user._id} onClick={this.exportGame2Results}>
                    Json&nbsp;
                    <i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
        <div className="text-center mt-5" id="loader">
          <span className="text-primary">Please wait..</span>
        </div>
      )
    return (
      <div>
        <Header />
        <SideNav />
        <div className="allUsersPage text-center">
          <h3 className="load text-center my-3">
            Registered Users ({this.state.users.length})
          </h3>
          <div className="d-flex justify-content-between mx-3">
            <div>
              <div class="input-group mb-3">
                <input 
                  onChange={this.onSearchChange}
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  aria-label="Search" 
                  aria-describedby="basic-addon2" />
              </div>
            </div>
            <div>
              <button className="btn btn-outline-dark px-4" exportType="csv" onClick={this.exportGame1Results}>
                <b>Game 1 (Csv)</b>
                <i className="fa fa-download ml-2" style={{ fontSize: "20px" }}></i>
              </button>
              <button className="btn btn-outline-dark px-4 ml-3" exportType="json"  onClick={this.exportGame1Results}>
                <b>Game 1 (Json)</b>
                <i className="fa fa-download ml-2" style={{ fontSize: "20px" }}></i>
              </button>
              <button className="btn btn-outline-dark px-4 ml-3" exportType="csv" onClick={this.exportGame2Results}>
                <b>Game 2 (Csv)</b>
                <i className="fa fa-download ml-2" style={{ fontSize: "20px" }}></i>
              </button>
              <button className="btn btn-outline-dark px-4 ml-3" exportType="json" onClick={this.exportGame2Results}>
                <b>Game 2 (Json)</b>
                <i className="fa fa-download ml-2" style={{ fontSize: "20px" }}></i>
              </button>
            </div>
          </div>
          <div className="text-primary text-center">
            <span>{this.state.success}</span>
          </div>
          {table}
          <div className="text-danger text-center">
            <span>{this.state.error}</span>
          </div>
        </div >
      </div >
    );
  }

  suspendUser = e => {
    this.setState({
      error: "",
      success: ""
    })
    e.persist()
    let x = e.currentTarget.value
    Axios.put(process.env.REACT_APP_BASE_URL + "/users/suspend", { _id: e.currentTarget.value },
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") },
      }
    ).then(result => {
      if (result.data.message) {
        this.setState({
          success: result.data.message,
          error: "",
        })
        this.getUsers(this.state.search)
      }
    }).catch(error => {
      console.log(error);
    })
  }

  deleteUser = e => {
    const user = this.state.users.find((dat) => dat._id === e.currentTarget.value);
    Swal.fire({
      title: 'Delete User!',
      text: `Are you sure you want to delete the user profile ${user.userName}?`,
      icon: 'warning',
      showCancelButton: true,
    }).then((confirmationResponse) =>{
      if(confirmationResponse.isDismissed) {
        return
      }
      this.setState({
        error: "",
        success: ""
      })
      Axios.delete(process.env.REACT_APP_BASE_URL + "/users/delete", {
        params: { _id: user._id },
        headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
      }).then(result => {
        if (result.data.message) {
          this.setState({
            success: result.data.message,
          })
          this.getUsers(this.state.search)
        }
      }).catch(error => {
        console.log(error);
      })
    })
  }

  restoreDeleteionOfUser = e => {
    this.setState({
      error: "",
      success: ""
    })
    e.persist();
    let x = e.currentTarget.value

    Axios.delete(process.env.REACT_APP_BASE_URL + "/users/restore-deletion", {
      params: { _id: e.currentTarget.value },
      headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
    }).then(result => {
      if (result.data.message) {
        this.setState({
          success: result.data.message,
        })
        this.getUsers(this.state.search)
      }
    }).catch(error => {
      console.log(error);
    })
  }

  hardDeleteUser = e => {
    const user = this.state.users.find((dat) => dat._id === e.currentTarget.value);
    Swal.fire({
      title: 'Hard Delete User!',
      text: `Are you sure you want to PERMANENTLY delete the user 
      profile ${user.userName}? This action cannot be reversed?`,
      icon: 'error',
      showCancelButton: true,
    }).then((confirmationResponse) =>{
      if(confirmationResponse.isDismissed) {
        return
      }
      this.setState({
        error: "",
        success: ""
      })
      Axios.delete(process.env.REACT_APP_BASE_URL + "/users/hard-delete", {
        params: { _id: user._id },
        headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
      }).then(result => {
        if (result.data.message) {
          this.setState({
            success: result.data.message,
          })
          this.getUsers(this.state.search)
        }
      }).catch(error => {
        console.log(error);
      })
    })
  }
  exportGame1Results = e => {
    this.setState({
      error: "",
      success: ""
    })
    e.persist();
    let x = e.currentTarget.value;
    const exportType = e.currentTarget.attributes.exportType.value;
    Axios.get(process.env.REACT_APP_BASE_URL + "/users/export", {
      params: { id: x, exportType },
      headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
    }).then(result => {
     
      let blob = null;
      if(exportType==='csv'){
        blob = new Blob([result.data]);
      } else {
        const json = JSON.stringify(result.data, null, 2);
        blob = new Blob([json], { type: "application/json" });
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${x || "all"}-game1.${exportType}`);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.log(error);
    })
  }
  exportGame2Results = e => {
    this.setState({
      error: "",
      success: ""
    })
    e.persist();
    let x = e.currentTarget.value;
    const exportType = e.currentTarget.attributes.exportType.value;
    Axios.get(process.env.REACT_APP_BASE_URL + "/users/export2", {
      params: { id: x, exportType },
      headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
    }).then(result => {

      let blob = null;
      if(exportType==='csv'){
        blob = new Blob([result.data]);
      } else {
        const json = JSON.stringify(result.data, null, 2);
        blob = new Blob([json], { type: "application/json" });
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${x || "all"}-game2.${exportType}`);
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.log(error);
    })
  }

}
export default AllUsers;
