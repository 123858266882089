import React, { Component } from 'react'
import Header from '../Header'
import SideNav from '../SideNav'
import { v1 as uuid } from "uuid";
import Axios from 'axios';

class Game2DisplayWords extends Component {
    state = {
        errors: "",
        success: "",
        category: "",
        allCategories: [],
        words: []
    }
    componentDidMount() {

        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.props.history.push("/admin");
                }
                else {
                    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/provide/all-categories", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                        },
                    }).then((result) => {
                        if (result.data.error) {
                            this.setState({
                                errors: result.data.error
                            })
                        }
                        if (result.data.catNames) {
                            this.setState({
                                allCategories: result.data.catNames,
                            });
                        }
                    }).catch((error) => console.log(error));
                }
            })
            .catch((error) => {
                return console.log(error);
            });
    }

    onChangeHandler = e => {
        this.setState({
            errors: "",
            success: "",
            words: [],
            category: e.currentTarget.value
        })
        if (e.currentTarget.value) {
            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/provide/all-words", {
                params: { catName: e.target.value },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                }
            }).then(result => {
                if (result.data.error) {
                    this.setState({
                        errors: result.data.error,
                        success: ""
                    })
                }
                if (result.data.words) {
                    this.setState({
                        words: result.data.words,
                        errors: "",
                        success: ""
                    })
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
    onDeleteHandler = e => {
        e.persist()
        this.setState({
            errors: "",
            success: ""
        })
        let x = e.currentTarget.value;


        Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/game2/delete/word`, {
            params: {
                category: this.state.category,
                word: e.currentTarget.value
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {

            if (result.data.error) {
                this.setState({
                    errors: result.data.error
                })
            }

            if (result.data.message) {
                this.setState({
                    words: this.state.words.filter(word => word !== x),
                    success: result.data.message
                })
            }

        }).catch(error => {
            console.log(error);
        })
    }
    render() {
        let wordsContainer = this.state.words.map((word) => (
            <tr key={uuid()}>
                <td className="p-0 m-0">{word}</td>
                <td className="p-0 m-0">
                    <button
                        onClick={this.onDeleteHandler}
                        value={word}
                        className="btn btn-outline-danger py-0 mx-3 float-center deleteWord"
                    >
                        <i className="fa fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        ));
        return (
            <div className="words">
                <Header />
                <SideNav />
                <div className="wordsPage text-center">
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    name="category"
                                    onChange={this.onChangeHandler}
                                    value={this.state.category}
                                >
                                    <option value="">
                                        Select Category
                                    </option>
                                    {this.state.allCategories.map(name => (
                                        <option key={uuid()} value={name}>{name}</option>
                                    ))}
                                </select>

                                <span className="text-danger">{this.state.errors}</span>
                            </div>
                        </form>
                    </div>
                    <div className="text-center text-primary">
                        <span>{this.state.success}</span>
                    </div>
                    <div className="container w-50 bg-light">
                        <table className="w-50 m-auto allCatTable table table-hover table-light">
                            <tbody>{wordsContainer}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default Game2DisplayWords