import React, { Component } from 'react'
import Header from './Header'
import SideNav from './SideNav'
import { v1 as uuid } from "uuid";
import Axios from 'axios';

class DisplayUserImages extends Component {
    state = {
        errors: "",
        success: "",
        username: "",
        usernames: [],
        images: [],
    }
    componentDidMount() {

        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.props.history.push("/admin");
                }
                else {
                    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/all-username", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                        },
                    }).then((result) => {
                        if (result.data.message) {
                            this.setState({
                                usernames: result.data.message,
                            });
                        }
                    }).catch((error) => console.log(error));
                }
            })
            .catch((error) => {
                return console.log(error);
            });
    }
    onChangeHandler = e => {

        this.setState({
            images: [],
            username: e.currentTarget.value
        })
        if (e.currentTarget.value) {
            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/user-images/" + e.target.value, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                }
            }).then(result => {
                if (result.data.error) {
                    this.setState({
                        errors: result.data.error,
                        success: ""
                    })
                }
                if (result.data.images) {
                    this.setState({
                        images: result.data.images,
                        errors: "",
                        success: ""
                    })
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
    onDeleteHandler = e => {
        e.persist()
        let x = e.currentTarget.value;

        Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/delete/user-image`, {
            params: {
                username: this.state.username,
                image: e.currentTarget.value
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            if (result.data.message) {

                this.setState({
                    images: this.state.images.filter(img => img !== x),
                    errors: "",
                    success: result.data.message
                })
            }
        }).catch(error => {
            console.log(error);
        })

    }
    render() {
        let imagesToBeDisplayed = this.state.images.map((image) => (
            <div key={uuid()} className="col-sm-6 col-md-4 col-lg-3">
                <img src={process.env.REACT_APP_STATIC_URL + "/" + image} alt="uploads"></img>
                <br />
                <button
                    className="btn btn-outline-danger py-0 float-right"
                    value={image}
                    onClick={this.onDeleteHandler}
                >
                    <i className="fa fa-trash-alt"></i>
                </button>
                <br />
                <br />
            </div>
        ));
        return (
            <div>
                <Header />
                <SideNav />
                <div className="imagesView">
                    <div className="container text-center">
                        <form>

                            <div className="form-group">
                                <select
                                    className="form-control"
                                    name="username"
                                    onChange={this.onChangeHandler}
                                    value={this.state.username}
                                >
                                    <option value="">
                                        Select Username
                                    </option>
                                    {this.state.usernames.map(name => (
                                        <option key={uuid()} value={name}>{name}</option>
                                    ))}
                                </select>
                                <span className="text-danger">{this.state.errors}</span>
                            </div>
                        </form>
                        <div className="text-center text-primary">
                            <span>{this.state.success}</span>
                        </div>

                        <div className="container-fluid bg-light w-100 mt-5">
                            <div className="row">{imagesToBeDisplayed}</div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default DisplayUserImages