import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";
import Swal from 'sweetalert2'

class AdminUsers extends Component {
  state = {
    error: "",
    success: "",
    admins: [],
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(process.env.REACT_APP_BASE_URL + "/admin/users/all", {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admin-access-token"),
            },
          })
            .then((result) => {
              if (result.data.error) {
                document.getElementById("loader").style.display = "none"
                this.setState({
                  error: result.data.error
                })
              }
              console.log(result.data);
              this.setState({
                admins: result.data,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  render() {
    let table = this.state.admins.length > 0 ? (
      <div className="container-fluid mt-5">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="text-info">Index</th>
              <th className="text-info">User ID</th>
              <th className="text-info">Email</th>
              <th className="text-info">Role</th>
              
              <th className="text-info">
                Actions
                <br />
                Edit/Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.admins.map((admin, index) => (
              <tr key={uuid()}>
                <th scope="row">{index + 1}</th>
                <td>{admin._id}</td>
                <td>{admin.email ? admin.email : "Nil"}</td>
                <td>{admin.role ? admin.role : "Nil"}</td>
                <td>
                  <div>
                    <button className="btn btn-warning mx-3" value={admin._id} onClick={() => this.props.history.push('/admin/users/edit/' + admin._id)}>
                      <i className="fa fa-edit"></i>
                    </button> 
                    <button className="btn btn-danger mx-3" value={admin._id} onClick={this.deleteUser}>
                      <i className="fa fa-trash-alt"></i>
                    </button> 
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
        <div className="text-center mt-5" id="loader">
          <span className="text-primary">Please wait..</span>
        </div>
      )
    return (
      <div>
        <Header />
        <SideNav />
        <div className="allUsersPage text-center">
          <h3 className="load text-center mt-3">
            Admins ({this.state.admins.length})
            <button 
              className="btn btn-info float-right mr-3"
              onClick={() => this.props.history.push("/admin/users/create")}
            >
              <i className="fa fa-plus"></i> New Admin
            </button>
          </h3>
          <div className="text-primary text-center">
            <span>{this.state.success}</span>
          </div>
          {table}
          <div className="text-danger text-center">
            <span>{this.state.error}</span>
          </div>
        </div >
      </div >
    );
  }

  deleteUser = e => {
    const user = this.state.admins.find((dat) => dat._id === e.currentTarget.value);
    Swal.fire({
      title: 'Delete User!',
      text: `Are you sure you want to delete the user profile ${user.email}?`,
      icon: 'warning',
      showCancelButton: true,
    }).then((confirmationResponse) =>{
      if(confirmationResponse.isDismissed) {
        return
      }
      this.setState({
        error: "",
        success: ""
      })

      Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/users", {
        params: { _id: user._id },
        headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
      }).then(result => {
        if (result.data.message) {
          this.setState({
            success: result.data.message,
            admins: this.state.admins.filter(admin => admin._id !== user._id)
          })
        }
      }).catch(error => {
        console.log(error);
      })
    })
  }
}
export default AdminUsers;
