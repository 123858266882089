import React, { Component } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Axios from "axios";
import { v1 as uuid } from "uuid";

class Step1 extends Component {
  state = {
    categoriesList: [],
    userType: "",
    displayImages1: "",
    category1: "",
    displayImages2: "",
    category2: "",
    displayImages3: "",
    category3: "",
    iterations: "",
    flashTime: "",
    error: "",
    success: "",
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-image-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              this.setState({
                categoriesList: result.data,
              });
            })
            .catch((error) => console.log(error));

          Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/settings/step2/A", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
          }).then(result => {
            this.setState({
              flashTime: result.data.flashTime
            })
          }).catch(error => {
            console.log(error);
          })
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  // On Change
  onChangeHandler = (e) => {
    if (e.target.name === "userType" && e.target.value === "A") {
      Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/settings/step2/A", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      })
        .then((result) => {
          this.setState({
            displayImages1: result.data.displayImages1,
            category1: result.data.responseImages1,
            displayImages2: result.data.displayImages2,
            category2: result.data.responseImages2,
            displayImages3: result.data.displayImages3,
            category3: result.data.responseImages3,
            iterations: result.data.iterations,
            flashTime: result.data.flashTime,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (e.target.name === "userType" && e.target.value === "B") {
      Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/settings/step2/B", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      })
        .then((result) => {
          this.setState({
            displayImages1: result.data.displayImages1,
            category1: result.data.responseImages1,
            displayImages2: result.data.displayImages2,
            category2: result.data.responseImages2,
            displayImages3: result.data.displayImages3,
            category3: result.data.responseImages3,
            iterations: result.data.iterations,
            flashTime: result.data.flashTime
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });

  };

  // On Setting submit
  onSettingSubmit = (e) => {
    e.preventDefault();
    const { userType, displayImages1, category1, displayImages2, category2, displayImages3, category3, iterations, flashTime } = this.state;
    Axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/steps/step2/settings",
      { userType, displayImages1, category1, displayImages2, category2, displayImages3, category3, iterations, flashTime },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      }
    )
      .then((result) => {
        if (result.data.error) {
          this.setState({
            error: result.data.error,
            success: "",
          });
        }
        if (result.data.message) {
          this.setState({
            success: result.data.message,
            error: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="stepTwoPage">
          <h1 className="text-info text-center display-4">STEP : 2</h1>
          <div className="container w-50 mt-4">
            <form
              className="w-75 m-auto"
              onSubmit={this.onSettingSubmit.bind(this)}
            >
              <p className="text-danger text-center">{this.state.error}</p>
              <p className="text-success text-center">{this.state.success}</p>
              <div className="">
                <label className=""> User Type </label>
              </div>
              <div className="form-group">
                <select
                  name="userType"
                  className="form-control"
                  value={this.state.userType}
                  onChange={this.onChangeHandler.bind(this)}
                >
                  <option value="" disabled>
                    -Select User Type-
                  </option>
                  <option value="A">Active</option>
                  <option value="B">Sham</option>
                </select>
              </div>

              <div className="">
                <label>Display Images 1</label>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="displayImages1"
                  id="display-images-1"
                  value={this.state.displayImages1}
                  onChange={this.onChangeHandler.bind(this)}
                >
                  <option value="">
                    -
                  </option>
                  {
                    this.state.userType === "A" &&
                    <option value="USER_IMAGE">
                      USER IMAGES
                    </option>
                  }
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label>Response 1</label>
              </div>
              <div className="form-group">
                <select
                  name="category1"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.category1}
                  className="form-control"
                >
                  <option value="">
                    -
                  </option>
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>

              <div className="">
                <label>Display Images 2</label>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="displayImages2"
                  id="display-images-2"
                  value={this.state.displayImages2}
                  onChange={this.onChangeHandler.bind(this)}
                >
                  <option value="">
                    -
                  </option>
                  {
                    this.state.userType === "A" &&
                    <option value="USER_IMAGE">
                      USER IMAGES
                    </option>
                  }
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label>Response 2</label>
              </div>
              <div className="form-group">
                <select
                  name="category2"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.category2}
                  className="form-control"
                >
                  <option value="">
                    -
                  </option>
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>

              <div className="">
                <label>Display Images 3</label>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="displayImages3"
                  id="display-images-3"
                  value={this.state.displayImages3}
                  onChange={this.onChangeHandler.bind(this)}
                >
                  <option value="">
                    -
                  </option>
                  {
                    this.state.userType === "A" &&
                    <option value="USER_IMAGE">
                      USER IMAGES
                    </option>
                  }
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label>Response 3</label>
              </div>
              <div className="form-group">
                <select
                  name="category3"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.category3}
                  className="form-control"
                >
                  <option value="">
                    -
                  </option>
                  {this.state.categoriesList.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <label>Iterations </label>
              </div>
              <div className="form-group">
                <input
                  type="number"
                  name="iterations"
                  value={this.state.iterations}
                  className="form-control"
                  onChange={this.onChangeHandler.bind(this)}
                  placeholder="Numbers of Iterations"
                  min="1"
                ></input>
              </div>

              <div className="">
                <label>Flash Time (<span className="text-secondary">in Milliseconds</span>) </label>
              </div>
              <div className="form-group">
                <input
                  type="number"
                  name="flashTime"
                  value={this.state.flashTime}
                  className="form-control"
                  onChange={this.onChangeHandler.bind(this)}
                  placeholder="Set Flash Time"
                  min="100"
                ></input>
              </div>

              <br />
              <div className="form-group">
                <button className="form-control btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Step1;
