import React, { Component } from 'react'
import Header from '../Header'
import SideNav from '../SideNav'
import { v1 as uuid } from "uuid";
import Axios from 'axios';


class Game2DisplayImages extends Component {
    state = {
        errors: "",
        success: "",
        category: "",
        allCategories: [],
        images: [],
    }
    componentDidMount() {

        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        }).then((res) => {
            if (res.data.error) {
                this.props.history.push("/admin");
            }
            else {
                Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/provide/all-categories", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                    },
                }).then((result) => {
                    if (result.data.error) {
                        this.setState({
                            errors: result.data.error
                        })
                    }
                    if (result.data.catNames) {
                        this.setState({
                            allCategories: result.data.catNames,
                        });
                    }
                }).catch((error) => console.log(error));
            }
        }).catch((error) => {
            return console.log(error);
        });
    }
    onChangeHandler = e => {

        this.setState({
            errors: "",
            success: "",
            images: [],
            category: e.currentTarget.value
        })
        if (e.currentTarget.value) {

            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/provide/all-images", {
                params: { catName: e.target.value },
                headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
            }).then(result => {
                if (result.data.error) {
                    this.setState({
                        errors: result.data.error,
                        success: ""
                    })
                }
                if (result.data.images) {
                    this.setState({
                        images: result.data.images,
                        errors: "",
                        success: ""
                    })
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
    onDeleteHandler = e => {
        e.persist()
        this.setState({
            errors: "",
            success: ""
        })
        let x = e.currentTarget.value;

        Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/game2/delete/image`, {
            params: {
                category: this.state.category,
                image: e.currentTarget.value
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            if (result.data.error) {
                this.setState({
                    errors: result.data.error,
                })
            }
            if (result.data.message) {

                this.setState({
                    images: this.state.images.filter(img => img !== x),
                    success: result.data.message
                })
            }
        }).catch(error => {
            console.log(error);
        })

    }
    render() {
        let imagesToBeDisplayed = this.state.images.map((image) => (
            <div key={uuid()} className="col-sm-6 col-md-4 col-lg-3">
                <img src={process.env.REACT_APP_STATIC_URL + "/" + image} alt="uploads" style={{ width: "90%", height: "80%" }}></img>
                <br />
                <button
                    className="btn btn-outline-danger py-0 float-right"
                    value={image}
                    onClick={this.onDeleteHandler}
                >
                    <i className="fa fa-trash-alt"></i>
                </button>
                <br /><br />
                <br /><br />
            </div>
        ));
        return (

            <div className="game2DisplayWords">
                <Header />
                <SideNav />
                <div className="wordsPage container text-center">
                    <form>

                        <div className="form-group">
                            <select
                                className="form-control"
                                name="category"
                                onChange={this.onChangeHandler}
                                value={this.state.category}
                            >
                                <option value="">
                                    Select Category
                                    </option>
                                {this.state.allCategories.map(name => (
                                    <option key={uuid()} value={name}>{name}</option>
                                ))}
                            </select>
                            <span className="text-danger">{this.state.errors}</span>
                        </div>
                    </form>
                    <div className="text-center text-primary">
                        <span>{this.state.success}</span>
                    </div>

                    <div className="container-fluid bg-light w-100 mt-5">
                        <div className="row">{imagesToBeDisplayed}</div>
                    </div>
                </div>

            </div>

        )
    }
}
export default Game2DisplayImages