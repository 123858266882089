import Axios from 'axios';
import React, { Component } from 'react'
import UserHeader from './UserHeader'
import ImageLoader from "./ImageLoader"
// import { Route, BrowserRouter } from "react-router-dom";
import renderHTML from 'react-render-html';
import shuffle from 'lodash.shuffle';
import '../../index.css';
import { SYNC_INTERVAL } from '../../constants';
import { evaluateBreakPoint } from '../../utils';
export default class UserStep3 extends Component {

    state = {
        pairs: [],
        flashTime: "",
        imagesTypes: [],
        userType: "",
        randomImages: [],
        randomFinalImages: [],
        secondCat: [],
        firstCat: [],
        numOfTimes: 0,
        numOfMatrices: 0,
        sessionId: null,
        iterations: 0,
        currentIteration: 0,
        pair1Clicked: false,
        pair2Clicked: false,
        clicks: 0,
        userChoose: [],
        error: "",
        pairDisplay: "none",
        startDisplay: "none",

        // According to Number of Matrices and number of pairs
        matricesComplete: 0,
        matricesPartial: 0,

        // Counters
        matricesCompleteCount: 0,
        matricesPartialCount: 0,
        matrixCount: 0,
        pairsTempLength: 0,
        numOfTimesCount: 1,
        step3Started: false,
        isFlashImagesOn: false,
        isShowingResults: false,
        isShowingTable: false,
        resultStatus: false,
        showGoodByeMessage: false,
        showStats: false,
        // Results
        rights: 0,
        wrongs: 0,
        // instructions
        instructions: "",

        // End Time
        endTime: "",

        // Latency
        results: [],

        dispTime: "",

        // result flash time
        resultFlashTime: "",
        previousSession: null,

        loadedImageURL1: "",
        loadedImageURL2: "",
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/user", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            },
        })
            .then((result) => {
                if (result.data.error) {
                    this.props.history.push("/game1");
                } else {
                    Promise.all([
                        Axios.get(process.env.REACT_APP_BASE_URL + "/users/game/session", {
                            params: { gameType: "GAME_ONE" },
                            headers: {
                              Authorization: "Bearer " + localStorage.getItem("user-access-token"),
                            },
                        }),
                        Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/instructions", {
                            params: { step: "3" }
                        })
                    ]).then(([result, result2]) => {
                        console.log('result, result2', result, result2)
                        this.setState({
                            previousSession: result.data.lastSession,
                            sessionId: result.data.lastSession?._id || null,

                        }, () => {
                            if(!this.state.previousSession) {
                                this.props.history.push("/game1");
                            } else if (this.state.previousSession.status === "STEP_1A_COMPLETED") {
                                this.props.history.push("/step1B");
                            } else if (this.state.previousSession.status === "STEP_1B_COMPLETED") {
                                this.props.history.push("/step2");
                            } 
                            if(!result2.data.isActive){
                                this.setState({
                                    step3Started: true,
                                })
                                this.displayResults(true);
                            } else {
                                this.setState({
                                    startDisplay: "block",
                                    instructions: result2.data.instructions
                                })
                            }
                        })
                    }).catch(error => {
                        console.log(error);
                    })
                }
            })
            .catch((error) => console.log(error));
    }


    handleSync = async (params = {}) => {
        const sum = this.state.results.reduce((accumulator, item) => accumulator + item.latency, 0)
        const averageLatency = sum / this.state.results.length;
      
        await Axios.post(process.env.REACT_APP_BASE_URL + "/users/start/step3/save-results", {
            ...params,
            step3Metadata: {
                pairs: this.state.pairs,
                flashTime: this.state.flashTime,
                imagesTypes: this.state.imagesTypes,
                userType: this.state.userType,
                secondCat: this.state.secondCat,
                firstCat: this.state.firstCat,
                numOfTimes: this.state.numOfTimes,
                numOfMatrices: this.state.numOfMatrices,
                resultFlashTime: this.state.resultFlashTime,
                iterations: this.state.iterations,
                currentIteration: this.state.currentIteration,
                matricesComplete: this.state.matricesComplete,
                matricesPartial: this.state.matricesPartial,
                matricesCompleteCount: this.state.matricesCompleteCount,
                matricesPartialCount: this.state.matricesPartialCount,
                matrixCount: this.state.matrixCount,
                pairsTempLength: this.state.pairsTempLength,
                numOfTimesCount: this.state.numOfTimesCount,
            },
            sessionId: this.state.sessionId,
            step3Results: {
                rights: this.state.rights,
                wrongs: this.state.wrongs,
                results: this.state.results,
                averageLatency
            }
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            }
        }).then((syncResponse) => {
            if(syncResponse?.data?.sessionId) {
                this.setState({
                    sessionId: syncResponse?.data?.sessionId
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }


    startStep3 = () => {
        this.setState({ step3Started: true })
        if(this.state?.previousSession?.step3Metadata?.pairs?.length) {
            this.setState({
               ...this.state.previousSession?.step3Metadata,
               rights: this.state.previousSession.step3Results.rights,
               wrongs: this.state.previousSession.step3Results.wrongs,
               results: this.state.previousSession.step3Results.results,
            },() => {
                this.startIteration();
            })
        } else {
            Axios.get(process.env.REACT_APP_BASE_URL + "/users/start/step3/images", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("user-access-token"),
                }
            }).then(result => {
                console.log(result.data);
                if (result.data.error) {
                    this.setState({
                        error: result.data.error
                    })
                } else {
                    this.setState({
                        pairs: result.data.pairs,
                        flashTime: result.data.flashTime,
                        imagesTypes: result.data.imagesTypes,
                        userType: result.data.userType,
                        secondCat: result.data.secondCat,
                        firstCat: result.data.firstCat,
                        numOfTimes: result.data.numOfTimes,
                        numOfMatrices: result.data.numOfMatrices,
                        resultFlashTime: result.data.resultFlashTime
                    })
    
                    this.calculateIterations(result.data.pairs.length, result.data.numOfMatrices)
    
                    this.startIteration();

                }
    
            }).catch(error => {
                console.log(error);
            })
        }
    
    }

    // Calculating Iterations with pairs and number of matrices

    calculateIterations(pairs, matrices) {

        let complete = Math.floor(matrices / pairs);

        this.setState({
            matricesComplete: complete,
        })
        let partial = matrices % pairs;
        this.setState({
            matricesPartial: partial
        })


    }
    async startIteration() {

        this.setState({
            isFlashImagesOn: true,
            pairDisplay: 'none'
        })

        setTimeout(() => {
            this.setState({
                pairDisplay: 'block'
            })
        }, 1000)
        setTimeout(() => {
            this.setState({
                isFlashImagesOn: false,
            })
            this.checkConditions()
        }, this.state.flashTime);
    }

    checkConditions() {
        const isIterationComplete = this.state.iterations === this.state.pairs.length;

        this.setState({
            userChoose: [],
        })

        if (isIterationComplete) {
            this.setState({
                iterations: 0
            })
            if (this.state.matricesCompleteCount !== this.state.matricesComplete) {

                this.setState({
                    matricesCompleteCount: this.state.matricesCompleteCount + 1
                })
            }
            console.log('this.state.matricesCompleteCount === this.state.matricesComplete', this.state.matricesCompleteCount === this.state.matricesComplete)
            if (this.state.matricesCompleteCount === this.state.matricesComplete) {

                if (this.state.numOfTimesCount === this.state.numOfTimes) {


                    this.displayResults();

                } else {
                    console.log("------------------Play Again------------------");

                    this.setState({
                        matricesCompleteCount: 0,
                        numOfTimesCount: this.state.numOfTimesCount + 1
                    })

                    Axios.get(process.env.REACT_APP_BASE_URL + "/users/start/step3/images", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("user-access-token"),
                        }
                    }).then(result => {

                        this.setState({
                            pairs: result.data.pairs,
                        })

                        this.startIteration();

                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
            else {
                this.proceedGame();
            }
            if (this.state.matrixCount === this.state.matricesComplete) {
            }
        } else {
            this.proceedGame();
        }
    }

    proceedGame() {

        // console.log(this.state);
        Axios.get(process.env.REACT_APP_BASE_URL + "/users/start/step3/random-images", {
            params: {
                pairs: this.state.pairs,
                pair1: this.state.pairs[this.state.iterations].first,
                pair2: this.state.pairs[this.state.iterations].second,
                userType: this.state.userType,
                imagesTypes: this.state.imagesTypes[this.state.iterations],
                secondCat: this.state.secondCat[this.state.iterations],
                firstCat: this.state.firstCat[this.state.iterations]
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            }

        }).then(result => {

            if (result.data.error) {
                this.setState({
                    error: result.data.error
                })
            }
            else {
                // console.log(result.data);
                const randomImages = result.data
                this.setState({
                    randomImages,
                    randomFinalImages: shuffle([
                        {
                            isImage: this.state.pairs[this.state.iterations].isFirstImage,
                            imageType: 'first',
                            data: this.state.pairs[this.state.iterations].first
                        },
                        {
                            isImage: this.state.pairs[this.state.iterations].isSecondImage,
                            imageType: 'second',
                            data: this.state.pairs[this.state.iterations].second
                        },
                        ...randomImages.images.slice(0, 6).map((r, i) => ({
                            isImage: r.length > 15,
                            imageType: 'random',
                            data: r

                        }))
                    ]),
                    dispTime: Date.now(),
                    isShowingResults: false,
                    isShowingTable: true

                })
            }

        }).catch(error => {
            console.log(error);
        })
    }

    imageClickHandler = e => {
        if (e.target.src)
            document.getElementById(e.target.id).style.padding = "10px"
        else
            document.getElementById(e.target.id).style.color = "red"

        this.setState({
            ...(e.target.id === "pair_img1" ? { pair1Clicked: true } : {}),
            ...(e.target.id === "pair_img2" ? { pair2Clicked: true } : {}),
            clicks: this.state.clicks + 1,
            userChoose: [...this.state.userChoose, e.target.src || document.getElementById(e.target.id).getAttribute("data-value")]
        }, this.flashResults)
    }

    postResults = () => {
        setTimeout(() => {
            console.log('CALlED')
            this.setState({
                iterations: this.state.iterations + 1,
                currentIteration: this.state.currentIteration + 1,
                clicks: 0,
                pair1Clicked: false,
                pair2Clicked: false,
                isShowingTable: false,
                isShowingResults: false,

            }, () => {
                if(evaluateBreakPoint(this.state.currentIteration, SYNC_INTERVAL)) {
                    this.handleSync();
                }
            })
            this.checkConditions();
        }, this.state.resultFlashTime);
    }

    flashResults() {
        const latency = (Date.now() - this.state.dispTime) / 1000;
        this.state.clicks === 2 && setTimeout(() => {
            this.setState({
                results: [...this.state.results,
                    {  
                        iteration: this.state.iterations,
                        matricesCompleteCount: this.state.matricesCompleteCount,
                        numOfTimesCount: this.state.numOfTimesCount,
                        isAccurate: this.state.pair1Clicked && this.state.pair2Clicked, 
                        userChose: {
                                first: this.state.userChoose[0],
                                second: this.state.userChoose[1]
                        },
                        correctPair: {
                            first: this.state.pairs[this.state.iterations].first,
                            second: this.state.pairs[this.state.iterations].second
                        },
                        latency
                    }
                ],
                isShowingResults: true,
                resultStatus: this.state.pair1Clicked && this.state.pair2Clicked,
                isShowingTable: false,
                ...(this.state.pair1Clicked && this.state.pair2Clicked ? { rights: this.state.rights + 1 } : { wrongs: this.state.wrongs + 1 })
            })
            this.postResults();
        }, 1000)
    }

    async displayResults(skipShowStats = false) {
        var dt = new Date(Date.now());
        // console.log(dt); // Gives Tue Mar 22 2016 09:30:00 GMT+0530 (IST)

        dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
        // console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)

        var offset = -300; //Timezone offset for EST in minutes.
        var estDate = new Date(dt.getTime() + offset * 60 * 1000);

        estDate = estDate.toString().split(' ').slice(0, 5).join(' ');

        this.setState({
            endTime: estDate
        },async () => {
            await this.handleSync({
                currentIteration: 0,
                endTime: this.state.endTime,
                status: "COMPLETED"
            })
            console.log("Game Over!");

            !skipShowStats && this.setState({
                showStats: true
            })
    
            setTimeout(() => {
                this.setState({
                    showGoodByeMessage: true,
                    showStats: false
                })
    
            }, skipShowStats ? 0 : 3000);
        })
    }

    render() {

        return (
            <div>
                <UserHeader />
                <div className="userStepThreePage container text-center p-4">

                    {
                        !this.state.step3Started &&
                        <>
                            <div className="stepThreeInstructions text-center" id="step3_instructions">
                                <p>{renderHTML(this.state.instructions)}</p>
                            </div>
                            <button className="btn btn-info startButton3 mx-auto mt-5" id="startButton3" onClick={this.startStep3} style={{display:this.state.startDisplay}}>
                                {
                                    this.state.previousSession ? 
                                    <h4 className='mb-0'>Resume!</h4>
                                    : <h4  className='mb-0'>Start!</h4>
                                }
                            </button>
                        </>
                    }

                    <div className="text-center">
                        <span className="text-danger text-center">{this.state.error}</span>
                    </div>

                    {
                        this.state.step3Started &&
                        <>
                            {
                                this.state.isFlashImagesOn &&
                                <div className="text-center" id="flash_images">
                                    <>
                                        <div class='text-primary'>Memorize These Pairs</div>

                                        {
                                            this.state.pairs.map((pair, i) =>
                                                <>


                                                    <span>Pair {i + 1}</span>
                                                    <div class="flashWord d-flex justify-content-center" id="parent_wrapper_of_flash_imgs">
                                                        {
                                                            pair.isFirstImage ?
                                                                <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                    <div className="position-absolute" id="_flash_images" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                        Loading...
                                                                    </div>
                                                                    <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${pair.first}`} id="flash_img" alt={`pair-image-${i + 1}`} style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                                </div>
                                                                :
                                                                <div class="wordInside" id="flash_word">
                                                                    <h4 id="flash_word">{pair.first}</h4>
                                                                </div>
                                                        }
                                                        {
                                                            pair.isSecondImage ?
                                                                <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                    <div className="position-absolute" id="_flash_images" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                        Loading...
                                                                    </div>
                                                                    <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${pair.second}`} id="flash_img" alt={`pair-image-${i + 2}`} style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                                </div>
                                                                :
                                                                <div class="wordInside" id="flash_word">
                                                                    <h4 id="flash_word">{pair.second}</h4>
                                                                </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                </div>
                            }
                            {
                                this.state.isShowingResults &&
                                <div className="text-center" id="flash_result">
                                    {
                                        this.state.resultStatus ?
                                            <>

                                                <br />
                                                <span class="text-success lead">Correct!</span>
                                                <br />
                                                <div class="flashWord">
                                                    {
                                                        this.state.pairs[this.state.iterations] && this.state.pairs[this.state.iterations].isFirstImage ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.iterations].first}`} id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.pairs[this.state.iterations].first}</h4>
                                                            </div>
                                                    }
                                                    {
                                                        this.state.pairs[this.state.iterations] && this.state.pairs[this.state.iterations].isSecondImage ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.iterations].second}`} id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.pairs[this.state.iterations].second}</h4>
                                                            </div>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                <span class="text-danger lead">Incorrect Pair</span>
                                                <br />
                                                <div class="flashWord">
                                                    {
                                                        this.state.userChoose[0] && this.state.userChoose[0].length > 15 ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={this.state.userChoose[0]} id="flash_img" id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.userChoose[0]} </h4>
                                                            </div>
                                                    }
                                                    {
                                                        this.state.userChoose[1] && this.state.userChoose[1].length > 15 ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={this.state.userChoose[1]} id="flash_img" id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.userChoose[1]}</h4>
                                                            </div>
                                                    }
                                                </div>
                                                <br />
                                                <br />
                                                <span class="text-primary lead">Correct Pair </span><br />
                                                <div class="flashWord">
                                                    {
                                                        this.state.pairs[this.state.iterations].isFirstImage ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.iterations].first}`} id="flash_img" id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.pairs[this.state.iterations].first}</h4>
                                                            </div>
                                                    }
                                                    {
                                                        this.state.pairs[this.state.iterations].isSecondImage ?
                                                            <div className="border border-dark position-relative" id="wrapper_of_flash_imgs" style={{ width: '8vw', height: '10vw', minWidth: '80px', minHeight: '90px' }}>
                                                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', fontSize: '0.65rem' }}>
                                                                    Loading...
                                                                </div>
                                                                <img className="position-relative" src={`${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.iterations].second}`} id="flash_img" id="flash_img" style={{ display: this.state.pairDisplay, width: '100%', height: '100%' }} />
                                                            </div>
                                                            :
                                                            <div class="wordInside" id="flash_word">
                                                                <h4 id="flash_word">{this.state.pairs[this.state.iterations].second}</h4>
                                                            </div>
                                                    }
                                                </div>
                                            </>
                                    }
                                </div>
                            }
                            {
                                this.state.isShowingTable &&
                                <div className="tableWrapper3">
                                    <table border="0" className="table2x4 text-center" id="matrix">
                                        <tbody>
                                            <tr>
                                                {
                                                    this.state.randomFinalImages.slice(0, 4).map((dat, i) =>
                                                        <>
                                                            {
                                                                dat.isImage ?
                                                                    <td id={i + 1}>
                                                                        <img src={`${process.env.REACT_APP_STATIC_URL}/${dat.data}`} id={`${dat.imageType === 'first'
                                                                            ? 'pair_img1'
                                                                            : dat.imageType === 'second'
                                                                                ? 'pair_img2'
                                                                                : `random_img${i + 1}`}`} onClick={this.imageClickHandler} />
                                                                    </td>
                                                                    :
                                                                    <td id={i + 1}>
                                                                        <div id={`${dat.imageType === 'first'
                                                                            ? 'pair_img1'
                                                                            : dat.imageType === 'second'
                                                                                ? 'pair_img2'
                                                                                : `random_img${i + 1}`}`} data-value={`${dat.data}`} onClick={this.imageClickHandler}>
                                                                            <h4 id={`${(dat.imageType === 'first')
                                                                                ? 'pair_img1'
                                                                                : dat.imageType === 'second'
                                                                                    ? 'pair_img2'
                                                                                    : `random_img${i + 1}`}`}>{dat.data} </h4>
                                                                        </div>
                                                                        <br />
                                                                    </td>
                                                            }

                                                        </>
                                                    )
                                                }
                                            </tr>
                                            <tr>
                                                {
                                                    this.state.randomFinalImages.slice(4, 8).map((dat, i) =>
                                                        <>
                                                            {
                                                                dat.isImage ?
                                                                    <td id={i + 1}>
                                                                        <img src={`${process.env.REACT_APP_STATIC_URL}/${dat.data}`} id={`${(dat.imageType === 'first')
                                                                            ? 'pair_img1'
                                                                            : (dat.imageType === 'second')
                                                                                ? 'pair_img2'
                                                                                : `random_img${i + 5}`}`} onClick={this.imageClickHandler} />
                                                                    </td>
                                                                    :
                                                                    <td id={i + 1}>
                                                                        <div id={`${(dat.imageType === 'first')
                                                                            ? 'pair_img1'
                                                                            : (dat.imageType === 'second')
                                                                                ? 'pair_img2'
                                                                                : `random_img${i + 5}`}`} data-value={`${dat.data}`} onClick={this.imageClickHandler}>
                                                                            <h4 id={`${(dat.imageType === 'first')
                                                                                ? 'pair_img1'
                                                                                : (dat.imageType === 'second')
                                                                                    ? 'pair_img2'
                                                                                    : `random_img${i + 5}`}`}>{dat.data}</h4>
                                                                        </div>
                                                                        <br />
                                                                    </td>
                                                            }

                                                        </>
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div id="displayResults">
                                {
                                    this.state.showStats &&
                                    <>
                                        <div className="stepThreeInstructions text-center" >
                                            <h5>Nicely done!</h5>
                                        </div>
                                        <div class="text-center">
                                            <h3># Correct : {this.state.rights}</h3>
                                            <h3># Incorrect : {this.state.wrongs}</h3>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.showGoodByeMessage &&
                                    <div class="text-center m-auto">
                                        <strong>All done—thank you!</strong>
                                        <p>You may now close this browser window</p>
                                    </div>
                                }
                            </div>
                        </>
                    }

                </div>
            </div >
        )
    }

    // startGame2 = () => {
    //     this.props.history.push("/play-game2")
    // }
}
