import React, { Component } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import { v1 as uuid } from "uuid";
import Axios from "axios";

class Step1 extends Component {
  state = {
    words: "",
    wordType: "",
    iterations: "",
    flashTime: "",
    nineXFlashTime: "",
    blankScreenFlashTime: "",
    error: "",
    success: "",
    settingsErrors: "",
    settingsSuccess: "",
    wordsA: [],
    wordsB: []
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/settings/step1B", {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("admin-access-token"),
            },
          })
            .then((result) => {
              if (result.data)
                this.setState({
                  iterations: result.data.iterations,
                  flashTime: result.data.flashTime,
                  nineXFlashTime: result.data.nineXFlashTime,
                  blankScreenFlashTime: result.data.blankScreenFlashTime,
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onWordChange = (e) => {
    this.setState({
      words: e.target.files,
    });
  };
  onSubmitHandler = (e) => {
    e.preventDefault();
    this.setState({
      success: "Uploading..",
      error: "",
      settingsErrors: "",
      settingsSuccess: "",
    });
    const data = new FormData();
    data.append("words", this.state.words[0]);
    data.append("wordType", this.state.wordType);
    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/steps/step1B/upload", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.setState({
            error: result.data.error,
            success: "",
            settingsErrors: "",
            settingsSuccess: "",
          });
        }
        if (result.data.message) {
          this.setState({
            success: result.data.message,
            error: "",
            settingsErrors: "",
            settingsSuccess: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSettingsSubmit = (e) => {
    e.preventDefault();
    Axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/steps/step1B/settings",
      {
        iterations: this.state.iterations,
        flashTime: this.state.flashTime,
        nineXFlashTime: this.state.nineXFlashTime,
        blankScreenFlashTime: this.state.blankScreenFlashTime,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      }
    )
      .then((result) => {
        if (result.data.message) {
          this.setState({
            settingsSuccess: result.data.message,
            settingsErrors: "",
            success: "",
            error: "",
          });
        }
        if (result.data.error) {
          this.setState({
            settingsErrors: result.data.error,
            settingsSuccess: "",
            success: "",
            error: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };
  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="stepOnePage">

          <div className="row">
            <div className="col-md-9">


              <h1 className="text-info text-center display-4">STEP : 1B</h1>

              <div className="container text-center p-5">
                <form
                  encType="multipart/form-data"
                  onSubmit={this.onSubmitHandler.bind(this)}
                >
                  <div>
                    <i className="fa fa-file" id="file-icon"></i>
                    <br />
                    <br />
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control-file w-50 m-auto"
                        name="words"
                        onChange={this.onWordChange.bind(this)}
                      />
                      <div className="form-group">
                        <br />
                        <select
                          name="wordType"
                          value={this.state.wordType}
                          onChange={this.onChangeHandler.bind(this)}
                        >
                          <option value="">Select Word Type</option>
                          <option value="A">Active</option>
                          <option value="B">Sham</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-info px-3" type="submit">
                    Upload Words
              </button>
                  <br />
                  <span className="text-danger">
                    {this.state.error ? this.state.error : ""}
                  </span>
                  <span className="text-success">
                    {this.state.success ? this.state.success : ""}
                  </span>
                </form>
                <form onSubmit={this.onSettingsSubmit.bind(this)}>
                  <div className="w-50 m-auto px-4 py-2 bg-light iterationsBox">
                    <h4 className="text-success">Settings</h4>
                    <hr />
                    <div className="form-group ">
                      <label className="float-left">Number of iterations</label>
                      <input
                        type="number"
                        name="iterations"
                        value={this.state.iterations}
                        onChange={this.onChangeHandler.bind(this)}
                        className="form-control w-100"
                        placeholder="Set No. of Iterations"
                        min="1"
                      ></input>
                      <br />
                      <span className="float-left">Flash Time <span className="text-secondary">(in Milliseconds)</span></span>
                      <input
                        type="number"
                        name="flashTime"
                        value={this.state.flashTime}
                        onChange={this.onChangeHandler.bind(this)}
                        className="form-control w-100"
                        placeholder="Set Flash Time"
                        min="100"
                      ></input><br />
                      <span className="float-left">Nine X Flash Time <span className="text-secondary">(in Milliseconds)</span></span>
                      <input
                        type="number"
                        name="nineXFlashTime"
                        value={this.state.nineXFlashTime}
                        onChange={this.onChangeHandler.bind(this)}
                        className="form-control w-100"
                        placeholder="Set Nine X Flash Time"
                        min="0"
                      ></input><br />
                      <span className="float-left">Blank Screen Flash Time <span className="text-secondary">(in Milliseconds)</span></span>
                      <input
                        type="number"
                        name="blankScreenFlashTime"
                        value={this.state.blankScreenFlashTime}
                        onChange={this.onChangeHandler.bind(this)}
                        className="form-control w-100"
                        placeholder="Set Blank Screen Flash Time"
                        min="0"
                      ></input><br />
                      <button className="btn btn-info w-100" type="submit">Save</button>
                    </div>
                  </div>
                  <p className="text-danger">{this.state.settingsErrors}</p>
                  <p className="text-success">{this.state.settingsSuccess}</p>
                </form>
              </div>

            </div>
            <div className="col-md-3 bg-light">
              <div className="mt-3">
                <div className="text-center">
                  <button className="btn btn-outline-info" id="display_button" onClick={this.viewAllWordsButton.bind(this)}>View All Words</button>

                </div>
                <div id="all_words_table">
                  <div className="text-center">
                    <button className="btn btn-outline-info mt-1" onClick={this.viewAllWordsButton.bind(this)}>
                      <i className="fa fa-redo"></i>
                    </button>

                  </div>
                  <p className="text-center"><strong>Category Active</strong></p>
                  <table className="table table-bordered table-hover table-light p-0">
                    <tbody>
                      {this.state.wordsA.map(word => (
                        <tr key={uuid()}>
                          <td className="p-0">{word}</td>
                          <td className="p-0"><button
                            onClick={this.onDeleteHandlerA}
                            value={word}
                            className="btn btn-outline-danger py-0 mx-3 float-right deleteWord"
                          >
                            <i className="fa fa-trash-alt"></i>
                          </button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <p className="text-center"><strong>Category Sham</strong></p>
                  <table className="table table-bordered table-hover table-light p-0">
                    <tbody>
                      {this.state.wordsB.map(word => (
                        <tr key={uuid()}>
                          <td className="p-0">{word}</td>
                          <td className="p-0"><button
                            onClick={this.onDeleteHandlerB}
                            value={word}
                            className="btn btn-outline-danger py-0 mx-3 float-right deleteWord"
                          >
                            <i className="fa fa-trash-alt"></i>
                          </button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  viewAllWordsButton = e => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/step1B/all-words", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    }).then(result => {
      if (result.data.success) {
        // console.log(result.data);
        this.setState({
          wordsA: result.data.userA,
          wordsB: result.data.userB
        })
        document.getElementById("all_words_table").style.display = "block"
        document.getElementById("display_button").style.display = "none"
      }
    }).catch(error => {
      console.log(error);
    })
  }

  onDeleteHandlerA = e => {
    e.persist()
    let x = e.currentTarget.value
    // console.log(e.currentTarget.value);
    Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/steps/step1B/word-a/" + e.currentTarget.value, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    }).then(result => {
      if (result.data.message) {
        this.setState({
          wordsA: this.state.wordsA.filter(word => word !== x)
        })
      }
    }).catch(error => {
      console.log(error);
    })
  }
  onDeleteHandlerB = e => {
    e.persist()
    let x = e.currentTarget.value

    Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/steps/step1B/word-b/" + e.currentTarget.value, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    }).then(result => {
      if (result.data.message) {
        console.log(result.data);
        this.setState({
          wordsB: this.state.wordsB.filter(word => word !== x)
        })
      }
    }).catch(error => {
      console.log(error);
    })
  }
}
export default Step1;
