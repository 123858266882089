import Axios from 'axios'
import React, { Component } from 'react'
import Header from '../Header'
import SideNav from '../SideNav'
import { v1 as uuid } from "uuid";

class AllLabels extends Component {
    state = {
        labels: [],
        success: "",
        error: "",
        version: ""
    }
    componentDidMount() {
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        }).then(result => {
            if (result.data.error) {
                this.props.history.push("/admin");
            }
        })
    }
    onChangeHandler = e => {

        this.setState({
            version: e.currentTarget.value,
            labels: [],
            success: "loading..",
            error: ""
        })

        Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/labels", {
            params: { version: e.currentTarget.value },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        }).then(result => {
            if (result.data.error) {
                this.setState({
                    error: result.data.error,
                    success: "",
                    labels: []
                })
                // document.getElementById("loader").style.display = "none"
            } if (result.data.labels) {

                this.setState({
                    labels: result.data.labels,
                    success: "",
                    error: "",
                })
            }
        }).catch(error => {
            console.log(error);
        })


    }
    render() {
        let tableData = this.state.labels.map(item =>
        (<tr key={item._id}>
            <td>{Object.values(item.leftLabels[0])[0]}
                <br />
                {Object.values(item.leftLabels[0])[1]}
            </td>
            <td>{Object.values(item.rightLabels[0])[0]}
                <br />
                {Object.values(item.rightLabels[0])[1]}
            </td>
            <td>{Object.values(item.leftCategories[0])[0]} - {item.leftTags[0].map(x => `${x},  `)}
                <br />
                {Object.values(item.leftCategories[0])[1]} - {item.leftTags[1].map(x => `${x},  `)}
            </td>
            <td>
                {Object.values(item.rightCategories[0])[0]} - {item.rightTags[0].map(x => `${x},  `)}
                <br />
                {Object.values(item.rightCategories[0])[1]} - {item.rightTags[1].map(x => `${x},  `)}
            </td>

            <td>{item.iterations}</td>
            <td>
                <button className="btn btn-warning mr-2" value={item._id} onClick={this.onEdit}>
                    <i className="fa fa-edit"></i>
                </button>
                <button className="btn btn-danger ml-2" value={item._id} onClick={this.onDelete}>
                    <i className="fa fa-trash-alt"></i>
                </button>
            </td>
        </tr>)
        )
        return (
            <div className="game2AllLabelsPage">
                <Header />
                <SideNav />
                <div className="container-fluid p-4 text-center">
                    {/* <h3 className="float-left">All Labels</h3> */}
                    <select className="form-control w-25 m-auto" name="version" onChange={this.onChangeHandler}
                        value={this.state.version}>
                        <option>-SELECT VERSION-</option>
                        <option value="1">Version 1</option>
                        <option value="2">Version 2</option>
                    </select>
                    <button className="btn btn-info float-right"
                        onClick={() => this.props.history.push("/admin/game2/add-label")}
                    ><i className="fa fa-plus"></i> New Block</button>

                </div>
                <br />
                <div className="container-fluid">
                    <div className="text-center text-success">{this.state.success}</div>
                    <div className="text-center text-danger">{this.state.error}</div>
                    {
                        this.state.labels.length > 0
                            ? (<table className="table table-hover table-borderless text-center">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="text-info text-center" colSpan="2"><span>Labels</span></th>
                                        <th className="text-info text-center" colSpan="2"><span>Categories</span></th>
                                        <th className="text-info text-center" colSpan="2"></th>
                                    </tr>
                                    <tr>
                                        <th className="text-info">Left </th>
                                        <th className="text-info">Right </th>
                                        <th className="text-info">Left </th>
                                        <th className="text-info">Right </th>
                                        <th className="text-info">#Iterations</th>
                                        <th className="text-info">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData}
                                </tbody>
                            </table>)
                            : ("")}
                </div>
            </div>
        )
    }
    onDelete = e => {
        e.persist()
        let x = e.currentTarget.value
        this.setState({
            error: "",
            success: ""
        })
        Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/game2/labels/delete-label", {
            params: {
                _id: e.currentTarget.value,
                version: this.state.version
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token")
            }
        }).then(result => {
            // console.log(result.data);
            if (result.data.error) {
                this.setState({
                    error: result.data.error,
                    success: ""
                })
            }
            if (result.data.message) {
                this.setState({
                    labels: this.state.labels.filter(label => label._id !== x),
                    success: result.data.message,
                    error: ""
                })
            }
        })
    }
    onEdit = e => {
        // console.log(e.currentTarget.value);
        this.props.history.push(`/admin/game2/update-label/${e.currentTarget.value}/${this.state.version}`)
    }
}
export default AllLabels