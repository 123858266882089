import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";

export default class UploadUsers extends Component {
  state = {
    images: [],
    imagesError: "",
    imagesSuccess: "",
    words: "",
    wordsError: "",
    wordsSuccess: "",
    allUserNamesImages: [],
    usernameWord: "",
    usernameImage: "",
  };

  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  // Image Change

  onImageChange = (e) => {
    this.setState({
      images: e.target.files,
    });
  };

  // Word Change

  onWordChange = (e) => {
    this.setState({
      words: e.target.files,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Uploading Images

  onImageSubmit = (e) => {
    e.preventDefault();
    this.setState({
      imagesSuccess: "Uploading..",
      imagesError: "",
    });
    const data = new FormData();
    data.append("usernameImage", this.state.usernameImage);

    let { images } = this.state;
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i]);
      }
    }

    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/upload-images-user", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            imagesError: res.data.error,
            imagesSuccess: "",
          });
        }
        if (res.data.message) {
          this.setState({
            imagesSuccess: res.data.message,
            imagesError: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  // Uploading Words

  onWordSubmit = (e) => {
    e.preventDefault();
    this.setState({
      wordsSuccess: "Uploading..",
      wordsError: "",
    });
    const data = new FormData();
    data.append("words", this.state.words[0]);
    data.append("usernameWord", this.state.usernameWord);
    // console.log(this.state);
    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/upload-words-user", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            wordsError: res.data.error,
            wordsSuccess: "",
          });
        }
        if (res.data.message) {
          this.setState({
            wordsSuccess: res.data.message,
            wordsError: "",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  onUserTypeChange = (e) => {
    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/users/" + e.target.value, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        console.log(result.data);
        this.setState({
          allUserNamesImages: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="container text-center uploadForms m-auto">
          <div className="w-75 m-auto pl-5 ml-5">
            <br />
            <select
              name="userType"
              className="form-control w-25 m-auto bg-info text-light"
              onChange={this.onUserTypeChange.bind(this)}
            >
              <option value="">Select User Type</option>
              <option value="A">Active</option>
              <option value="B">Sham</option>
            </select>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 col-lg-6 column1">
              <h4>Upload Images</h4>
              <form onSubmit={this.onImageSubmit.bind(this)}>
                <div>
                  <i className="fa fa-image" id="img-icon"></i>

                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      name="images"
                      onChange={this.onImageChange.bind(this)}
                      multiple
                    />
                  </div>
                  <div className="form-group">
                    <select
                      name="usernameImage"
                      value={this.state.usernameImage}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">Select Username</option>
                      {this.state.allUserNamesImages.map((userName) => (
                        <option key={uuid()} value={userName}>
                          {userName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <button className="btn btn-info" type="submit">
                  Upload Images
                </button>
                <br />
                <span className="text-danger">
                  {this.state.imagesError ? this.state.imagesError : ""}
                </span>
                <span className="text-success">
                  {this.state.imagesSuccess ? this.state.imagesSuccess : ""}
                </span>
              </form>
            </div>
            <div className="col-md-6 col-lg-6 column2">
              <h4>Upload Words</h4>

              <form
                encType="multipart/form-data"
                onSubmit={this.onWordSubmit.bind(this)}
              >
                <div>
                  <i className="fa fa-file" id="file-icon"></i>
                  <br />
                  <br />
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      name="words"
                      onChange={this.onWordChange.bind(this)}
                    />
                    <br />
                    <div className="form-group">
                      <select
                        name="usernameWord"
                        value={this.state.usernameWord}
                        onChange={this.onChange.bind(this)}
                      >
                        <option value="">Select Username</option>
                        {this.state.allUserNamesImages.map((userName) => (
                          <option key={uuid()} value={userName}>
                            {userName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <button className="btn btn-info px-3" type="submit">
                  Upload Words
                </button>
                <br />
                <span className="text-danger">
                  {this.state.wordsError ? this.state.wordsError : ""}
                </span>
                <span className="text-success">
                  {this.state.wordsSuccess ? this.state.wordsSuccess : ""}
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
