import Axios from "axios";
import React, { Component } from "react";

class Game2Login extends Component {
    state = {
        errorMsg: "",
        userName: "",
        password: "",
        gameType: "GAME_2"
    };

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onSubmitHandler = (e) => {
        // console.log(process.env.REACT_APP_BASE_URL);
        e.preventDefault();
        Axios.post(process.env.REACT_APP_BASE_URL + "/users/login", this.state)
            .then((result) => {
                if (result.data.error) {
                    this.setState({
                        errorMsg: result.data.error,
                    });
                } else {
                    localStorage.setItem("user-access-token", result.data.accessToken);

                    this.props.history.push("/play-game2");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    render() {
        return (
            <div>
                <div className="container-fluid p-3 px-5 bg-dark adminLoginHeader">
                    <h1 className="text-warning">PittsApp</h1>
                    <h1 className="text-info">User Login-2</h1>
                </div>
                <form
                    className="m-auto pt-5 mt-5 adminLoginForm"
                    onSubmit={this.onSubmitHandler.bind(this)}
                >
                    <div className="container text-center text-danger">
                        <span>
                            {this.state.errorMsg ? `Error : ${this.state.errorMsg}` : ""}
                        </span>
                    </div>
                    <div className="form-group my-5">
                        <input
                            className="form-control"
                            type="text"
                            name="userName"
                            value={this.state.userName}
                            onChange={this.onChangeHandler.bind(this)}
                            placeholder="Enter your username"
                        />
                    </div>

                    <div className="form-group my-5">
                        <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangeHandler.bind(this)}
                            placeholder="Enter Password"
                        />
                    </div>
                    <div className="form-group my-5">
                        <button className="btn btn-outline-dark float-right" type="submit">
                            Login
            </button>
                    </div>
                </form>
            </div>
        );
    }
}
export default Game2Login;
