import Axios from 'axios'
import React, { Component } from 'react'
import Header from './Header'
import SideNav from './SideNav'
import { v1 as uuid } from "uuid";
import Swal from 'sweetalert2'
import { jwtDecode } from 'jwt-decode';

class SuspendedUsers extends Component {
    state = {
        error: "",
        success: "",
        isLoading: false,
        suspendedUsers: [],
        isSuperAdmin: false,
    }
    componentDidMount() {
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") },
        }).then((res) => {
            if (res.data.error) {
                this.props.history.push("/admin");
            }
            else {
                this.setState({
                    isSuperAdmin: jwtDecode(localStorage.getItem("admin-access-token")).role === "SUPER_ADMIN"
                  });
                this.getUsers();
            }
        }).catch(error => {
            console.log(error);
        })
    }

    getUsers = () => {
        this.setState({
            isLoading:true
        })
        Axios.get(process.env.REACT_APP_BASE_URL + "/users/suspended-users", {
            headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
        }).then(result => {
            if (result.data.error) {
                document.getElementById("loader").style.display = "none"
                this.setState({
                    error: result.data.error,
                    success: ""
                })
            } else {
                this.setState({
                    suspendedUsers: result.data
                })
            }
        }).catch(error => {
            console.log(error);
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }
    render() {
        let table = !this.state.isLoading ? (
            <div className="container-fluid mt-5">
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th className="text-info">Index</th>
                            <th className="text-info">Username</th>
                            <th className="text-info">User ID</th>
                            <th className="text-info">User Cat</th>
                            <th className="text-info">Email</th>
                            {/* <th className="text-info">Game 1 <br /> Latency</th>
                    <th className="text-info">Game 1 <br /> Timestamps</th>
                    <th className="text-info">Step 1 score
                      <br />
                      <span># Correct |# Incorrect</span>
                    </th>
                    <th className="text-info">Step 3 score
                      <br />
                      # Correct |# Incorrect
                    </th> */}
                            <th className="text-info">
                                Actions
                      </th>
                            <th className="text-info">Game1 Export</th>
                            <th className="text-info">Game2 Export</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.suspendedUsers.map((user, index) => (
                            <tr key={uuid()}>
                                <th scope="row">{index + 1}</th>
                                <td>{user.userName}</td>
                                <td>{user._id}</td>
                                <td>{user.userType === "A" ? "Active" : "Sham"}</td>
                                <td>{user.email ? user.email : "Nil"}</td>
                                <td>
                                    <div>
                                        <button className="btn btn-success mx-3" onClick={this.sustainUser} value={user._id}>
                                            Sustain&nbsp;<i className="fa fa-user"></i>
                                        </button>
                                    </div>
                                    <div className="mt-2">
                                        { !user.isDeleted ? 
                                            <button className="btn btn-danger mx-3" value={user._id} onClick={this.deleteUser}>
                                            Delete&nbsp;
                                            <i className="fa fa-trash-alt"></i>
                                            </button>
                                        : user.isDeleted && this.state.isSuperAdmin ? 
                                            <button className="btn btn-warning mx-3" value={user._id} onClick={this.restoreDeleteionOfUser}>
                                            Restore&nbsp;
                                            <i className="fa fa-user-slash"></i>
                                            </button>
                                        : null
                                        }
                                    </div>
                                    <div className="mt-2">
                                        {
                                        this.state.isSuperAdmin && 
                                        <button className="btn btn-danger mx-3" value={user._id} onClick={this.hardDeleteUser}>
                                            Hard Delete&nbsp;
                                            <i className="fa fa-trash-alt"></i>
                                        </button>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <button className="btn btn-outline-dark px-4" value={user._id} exportType="csv" onClick={this.exportResults}>
                                        Csv&nbsp;<i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                                    </button>
                                    <button className="btn btn-outline-dark px-4" value={user._id} exportType="json" onClick={this.exportResults}>
                                        Json&nbsp;<i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                                    </button>
                                </td>
                                <td>
                                    <button className="btn btn-outline-dark px-4" value={user._id} exportType="csv" onClick={this.exportGame2Results}>
                                        Csv&nbsp;<i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                                    </button>
                                    <button className="btn btn-outline-dark px-4" value={user._id} exportType="json" onClick={this.exportGame2Results}>
                                        Json&nbsp;<i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
                <div className="text-center mt-5" id="loader">
                    <span className="text-primary">Please wait..</span>
                </div>
            )
        return (
            <div>
                <Header />
                <SideNav />
                <div className="container-fluid suspendedUserPage text-center">
                    <h3 className="load text-center">
                        Suspended Users ({this.state.suspendedUsers.length})
                    </h3>
                    <div className="text-primary text-center">
                        <span>{this.state.success}</span>
                    </div>
                    {table}
                    <div className="text-danger text-center">
                        <span>{this.state.error}</span>
                    </div>
                </div>
            </div >
        )
    }
    sustainUser = e => {
        this.setState({
            error: "",
            success: ""
        })
        e.persist()
        let x = e.currentTarget.value
        Axios.put(process.env.REACT_APP_BASE_URL + "/users/sustain", { _id: e.currentTarget.value },
            {
                headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") },
            }
        ).then(result => {
            if (result.data.message) {
                this.setState({
                    success: result.data.message,
                    error: "",
                })
                this.getUsers()
            }
        }).catch(error => {
            console.log(error);
        })
    }
    deleteUser = e => {
        const user = this.state.suspendedUsers.find((dat) => dat._id === e.currentTarget.value);
        Swal.fire({
          title: 'Delete User!',
          text: `Are you sure you want to delete the user profile ${user.userName}?`,
          icon: 'warning',
          showCancelButton: true,
        }).then((confirmationResponse) =>{
          if(confirmationResponse.isDismissed) {
            return
          }
            this.setState({
                error: "",
                success: ""
            })

            Axios.delete(process.env.REACT_APP_BASE_URL + "/users/delete", {
                params: { _id: user._id },
                headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
            }).then(result => {
                if (result.data.message) {
                    this.setState({
                        success: result.data.message,
                    })
                    this.getUsers()
                }
            }).catch(error => {
                console.log(error);
            })
        })
    }

  restoreDeleteionOfUser = e => {
    this.setState({
      error: "",
      success: ""
    })
    e.persist();
    let x = e.currentTarget.value

    Axios.delete(process.env.REACT_APP_BASE_URL + "/users/restore-deletion", {
      params: { _id: e.currentTarget.value },
      headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
    }).then(result => {
      if (result.data.message) {
        this.setState({
            success: result.data.message,
        })
        this.getUsers()
      }
    }).catch(error => {
      console.log(error);
    })
  }

    hardDeleteUser = e => {
        const user = this.state.suspendedUsers.find((dat) => dat._id === e.currentTarget.value);
        Swal.fire({
            title: 'Hard Delete User!',
            text: `Are you sure you want to PERMANENTLY delete the user 
            profile ${user.userName}? This action cannot be reversed?`,
            icon: 'error',
            showCancelButton: true,
        }).then((confirmationResponse) =>{
            if(confirmationResponse.isDismissed) {
                return
            }
            this.setState({
                error: "",
                success: ""
            })

            Axios.delete(process.env.REACT_APP_BASE_URL + "/users/hard-delete", {
                params: { _id: user._id },
                headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
            }).then(result => {
            if (result.data.message) {
                this.setState({
                    success: result.data.message,
                })
                this.getUsers()

            }
            }).catch(error => {
                console.log(error);
            })
        })
    }
    exportResults = e => {
        this.setState({
            error: "",
            success: ""
        })
        e.persist();
        let x = e.currentTarget.value;
        const exportType = e.currentTarget.attributes.exportType.value;
        Axios.get(process.env.REACT_APP_BASE_URL + "/users/export", {
            params: { id: x },
            headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
        }).then(result => {
            let blob = null;
            if(exportType==='csv'){
                blob = new Blob([result.data]);
            } else {
                const json = JSON.stringify(result.data, null, 2);
                blob = new Blob([json], { type: "application/json" });
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${x}.${exportType}`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
        })
    }
    exportGame2Results = e => {
        this.setState({
          error: "",
          success: ""
        })
        e.persist();
        let x = e.currentTarget.value;
        const exportType = e.currentTarget.attributes.exportType.value;
        Axios.get(process.env.REACT_APP_BASE_URL + "/users/export2", {
          params: { id: x },
          headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
        }).then(result => {
            let blob = null;
            if(exportType==='csv'){
                blob = new Blob([result.data]);
            } else {
                const json = JSON.stringify(result.data, null, 2);
                blob = new Blob([json], { type: "application/json" });
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${x || "all"}-game2.${exportType}`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
          console.log(error);
        })
      }
}

export default SuspendedUsers