import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

class AdminEditForm extends Component {
  state = {
    id: "",
    email: "",
    password: "",
    role: "",
    errorMessage: "",
    successMessage: "",
  };

  componentDidMount() {
    //   Check Authorization
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          const { match: { params: {id} } } = this.props;
          console.log('id', id)
          if(id) {
            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/users/" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
              },
            })
            .then((res) => {
              this.setState({
                id,
                email: res.data.email,
                role: res.data.role
              })
            })
            .catch((error) => {
              return console.log(error);
            });
          }
        }

      })
      .catch((error) => {
        return console.log(error);
      });
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //   Submit Handler

  onSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state);
    if(this.state.id) {
      Axios.put(process.env.REACT_APP_BASE_URL + "/admin/users/" + this.state.id, this.state, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.error) {
            this.setState({
              errorMessage: result.data.error,
              successMessage: "",
            });
          }
          if (result.data.message) {
            this.setState({
              successMessage: result.data.message,
              errorMessage: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Axios.post(process.env.REACT_APP_BASE_URL + "/admin/users", this.state, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
        },
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.error) {
            this.setState({
              errorMessage: result.data.error,
              successMessage: "",
            });
          }
          if (result.data.message) {
            this.setState({
              successMessage: result.data.message,
              errorMessage: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="passwordPage">
          <h3 className="text-center mt-5">Admin {this.state.id ? "Edit" : ""} Form</h3>
          <div className="text-center bg-light passwordFiledsWrapper">
            <span className="text-danger">
              {this.state.errorMessage ? this.state.errorMessage : ""}
            </span>

            <span className="text-success">
              {this.state.successMessage ? this.state.successMessage : ""}
            </span>
            <form onSubmit={this.onSubmitHandler.bind(this)}>
              <div className="form-group">
                <label className="float-left">Email</label>
              </div>
              <div className="form-group">
                <input
                  name="email"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.email}
                  type="text"
                ></input>
              </div>
              <div className="form-group">
                <label className="float-left">Password</label>
              </div>
              <div className="form-group">
                <input
                  name="password"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.password}
                  type="password"
                ></input>
              </div>
              <div>
                <select
                  className="form-control"
                  name="role"
                  value={this.state.role}
                  onChange={this.onChangeHandler.bind(this)}
                >
                  <option value="">
                    Select Role
                  </option>
                  <option value="ADMIN">
                    ADMIN
                  </option>
                  <option value="SUPER_ADMIN">
                    SUPER_ADMIN
                  </option>
                </select>
              </div>
              <div className="form-group mt-3">
                <button type="submit" className="btn btn-info float-right">
                  {
                    this.state.id ? "Save" : "Add"
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminEditForm;
