import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";

class AllWords extends Component {
  state = {
    category: "",
    wordType: "",
    errors: "",
    allWords: [],
    deleteMessage: "",
    selectImageCategory: [],
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-word-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              if (result.data) {
                this.setState({
                  selectImageCategory: result.data,
                });
              }
              if (result.data.error) {
                console.log(result.data.error);
                this.setState({
                  imagesError: result.data.error,
                });
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }
  onSubmitHandler = (e) => {
    e.preventDefault();
    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/providing/words`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
      params: {
        category: this.state.category,
        wordType: this.state.wordType,
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.setState({
            errors: result.data.error,
            allWords: [],
            deleteMessage: "",
          });
        } else {
          this.setState({
            allWords: result.data,
            errors: "",
            deleteMessage: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let wordsContainer = this.state.allWords.map((word) => (
      <tr key={uuid()}>
        <td className="p-0 m-0">{word}</td>
        <td className="p-0 m-0">
          <button
            onClick={this.onDeleteHandler.bind(this)}
            value={word}
            className="btn btn-outline-danger py-0 mx-3 deleteWord"
          >
            <i className="fa fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    ));

    return (
      <div className="words">
        <Header />
        <SideNav />
        <div className="wordsPage text-center">
          <div className="container">
            <form onSubmit={this.onSubmitHandler.bind(this)}>
              <div className="form-group">
                <select
                  className="form-control"
                  name="wordType"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.wordType}
                >
                  <option value="">Select Type</option>
                  <option value="A">Active</option>
                  <option value="B">Sham</option>
                </select>
                <select
                  className="form-control"
                  name="category"
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.category}
                >
                  <option value="">Select Words Category</option>
                  {this.state.selectImageCategory.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>

                <button className="form-control btn btn-dark" type="submit">
                  <i className="fa fa-search"></i> Search
              </button>
                <span className="text-danger">{this.state.errors}</span>
                <span className="text-primary">{this.state.deleteMessage}</span>
              </div>
            </form>
          </div>
          <div className="container-fluid bg-light">
            <table className="w-50 m-auto allCatTable table table-hover table-light">
              {/* <tbody>{wordsToBeDisplayed}</tbody> */}
              <tbody>{wordsContainer}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDeleteHandler = (e) => {
    Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/delete/words`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
      params: {
        word: e.currentTarget.value,
        category: this.state.category,
        wordType: this.state.wordType,
      },
    })
      .then((result) => {
        this.setState({
          deleteMessage: result.data.message,
          errors: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });

    let filteredState = this.state.allWords.filter(
      (word) => word !== e.currentTarget.value
    );
    this.setState({
      allWords: filteredState,
    });
  };
}
export default AllWords;
