import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { v1 as uuid } from "uuid";

class DisplayImages extends Component {
  state = {
    imageCategory: "",
    imageType: "",
    images: [],
    errors: "",
    deleteMessage: "",
    selectImageCategory: [],
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-image-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              this.setState({
                selectImageCategory: result.data,
              });
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/providing/images`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
      params: {
        imageType: this.state.imageType,
        imageCategory: this.state.imageCategory,
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.setState({
            errors: result.data.error,
            images: [],
            deleteMessage: "",
          });
        } else {
          this.setState({
            images: result.data,
            errors: "",
            deleteMessage: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onDeleteHandler = (e) => {
    // console.log(e.currentTarget.value);
    Axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/delete/images/`, {
      params: {
        imageType: this.state.imageType,
        imageCategory: this.state.imageCategory,
        imagePath: e.currentTarget.value,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        // console.log(result.data.message);
        this.setState({
          deleteMessage: result.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({
      images: this.state.images.filter((img) => img !== e.currentTarget.value),
    });
  };
  render() {
    let imagesToBeDisplayed = this.state.images.map((image) => (
      <div key={uuid()} className="col-sm-6 col-md-4 col-lg-3">
        <img src={process.env.REACT_APP_STATIC_URL + "/" + image} alt="uploads"></img>
        <br />
        <button
          className="btn btn-outline-danger py-0 float-right"
          value={image}
          onClick={this.onDeleteHandler.bind(this)}
        >
          <i className="fa fa-trash-alt"></i>
        </button>
        <br />
        <br />
      </div>
    ));
    return (
      <div>
        <Header />
        <SideNav />
        <div className="imagesView">
          <div className="container text-center">
            <form className="" onSubmit={this.onSubmitHandler.bind(this)}>
              <div className="form-group">
                <select
                  className="form-control"
                  name="imageType"
                  onChange={this.onChangeHandler.bind(this)}
                  defaultValue="default"
                >
                  <option value="default" disabled>
                    Select Type
                  </option>
                  <option value="A">Active</option>
                  <option value="B">Sham</option>
                </select>

                <select
                  className="form-control"
                  onChange={this.onChangeHandler.bind(this)}
                  name="imageCategory"
                  value={this.state.imageCategory}
                >
                  <option value="">Select Image Category</option>
                  {this.state.selectImageCategory.map((cat) => (
                    <option key={uuid()} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>

                <button className="form-control btn btn-dark" type="submit">
                  <i className="fa fa-search"></i> Search
                </button>
                <span className="text-danger">{this.state.errors}</span>
              </div>
            </form>
          </div>
          <div className="container text-center">
            <span className="text-primary">{this.state.deleteMessage}</span>
          </div>
          <div className="container-fluid bg-light w-100">
            <div className="row">{imagesToBeDisplayed}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default DisplayImages;
