import Axios from "axios";
import React, { Component } from "react";
import UserHeader from "./UserHeader";
import { evaluateBreakPoint, prefetchImages } from "../../utils";
import { SYNC_INTERVAL } from "../../constants";

const QUADRANTS = {
  1: "first_quad",
  2: "second_quad",
  3: "third_quad",
  4: "fourth_quad",
}
class UserStep2 extends Component {
  state = {
    iterations: "",
    currentIteration: 0,
    breakPoint: null,
    pairs: [],
    randomResponseNum: "",
    flashTime: "",
    error: "",
    sessionId: null,

    instructions: "",
    previousSession: null,

    // Latency
    results: [],
    dispTime: "",

    average: "",

  };
  componentDidMount() {

    document.getElementById("breakResumeButton").style.display = "none";
    document.getElementById("breakInstructions").style.display = "none";
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/user", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user-access-token"),
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.props.history.push("/game1");
        } else {
          Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/instructions", {
            params: { step: "2" }
          }).then(result => {
            this.setState({
              instructions: result.data.instructions,
              breakPoint: result.data.breakPoint || null
            })
            document.getElementById("step2_instructions").innerHTML = result.data.instructions
            document.getElementById("breakInstructions").innerHTML = result.data.breakPointInstructions

          }).catch(error => {
            console.log(error);
          })

          Axios.get(process.env.REACT_APP_BASE_URL + "/users/game/session", {
            params: { gameType: "GAME_ONE" },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            },
          }).then(result => {
              this.setState({
                previousSession: result.data.lastSession,
                sessionId: result.data.lastSession?._id || null,
              }, () => {
                if(!this.state.previousSession) {
                  this.props.history.push("/game1");
                } else if (this.state.previousSession.status === "STEP_1A_COMPLETED") {
                  this.props.history.push("/step1B");
                } else if (this.state.previousSession.status === "STEP_2_COMPLETED") {
                  this.props.history.push("/step3");
                }
              })
          }).catch(error => {
            console.log(error);
          })
        }
      })
      .catch((error) => console.log(error));
  }


  showBreakPoint = () => {
    document.getElementById("breakResumeButton").style.display = "block";
    document.getElementById("breakInstructions").style.display = "block";

    document.getElementById("tableWrapper").style.display = "none"

  }
  resumeBreak = () => {
    document.getElementById("breakResumeButton").style.display = "none";
    document.getElementById("breakInstructions").style.display = "none";

    document.getElementById("tableWrapper").style.display = "block"

  }
  

  handleSync = async (params = {}) => {
    const sum = this.state.results.reduce((accumulator, item) => accumulator + item.latency, 0)
    const averageLatency = sum / this.state.results.length
    await Axios.post(process.env.REACT_APP_BASE_URL + "/users/start/step3/save-results", {
      ...params,
      step2Metadata: {
        pairs: this.state.pairs,
        flashTime: this.state.flashTime,
        currentIteration: this.state.currentIteration,
        iterations: this.state.iterations
      },
      sessionId: this.state.sessionId,
      step2Results: {
        results: this.state.results,
        averageLatency
      }
    }, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("user-access-token"),
        }
    }).then((syncResponse) => {
      if(syncResponse?.data?.sessionId) {
          this.setState({
              sessionId: syncResponse?.data?.sessionId
          })
      }
    }).catch(error => {
        console.log(error);
    })
  }

  cacheImages = (pairs) => {
    const set = new Set();
    for(let pair of pairs) {
      set.add(`${process.env.REACT_APP_STATIC_URL}/${pair.displayImage}`);
      set.add(`${process.env.REACT_APP_STATIC_URL}/${pair.responseImage}`);
    }
    prefetchImages([...set.values()])
  }

  startStep2 = () => {
    document.getElementById("step2_instructions").style.display = "none"
    document.getElementById("startButton2").style.display = "none"
    document.getElementById("tableWrapper").style.display = "block"
    if(this.state?.previousSession?.step2Metadata?.pairs.length) {
      this.cacheImages(this.state.previousSession?.step2Metadata.pairs);
      this.setState({
        pairs: this.state.previousSession?.step2Metadata.pairs,
        flashTime: this.state.previousSession?.step2Metadata.flashTime,
        iterations: this.state.previousSession?.step2Metadata.iterations,
        currentIteration: this.state.previousSession?.step2Metadata.currentIteration,
        results: this.state.previousSession?.step2Results.results,
        dispTime: Date.now()
      }, () => {
        let image = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
        document.getElementById(QUADRANTS[this.state.pairs[this.state.currentIteration].quadrant]).innerHTML = image
        document.getElementById('user_img').onclick = this.onImageClick;
      })
    } else {
      Axios.get(process.env.REACT_APP_BASE_URL + "/users/start/step2/images", {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("user-access-token"),
        },
      })
        .then((result) => {
          if (result.data.error) {
            this.setState({
              error: result.data.error
            })
            document.getElementById("tableWrapper").style.display = "none"
  
          }
          if (result.data.success) {
            this.cacheImages(result.data.pairs);
            this.setState({
              pairs: result.data.pairs,
              iterations: result.data.iterations,
              flashTime: result.data.flashTime,
              dispTime: Date.now()
            }, () => {
              let image = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
              document.getElementById(QUADRANTS[this.state.pairs[this.state.currentIteration].quadrant]).innerHTML = image
              document.getElementById('user_img').onclick = this.onImageClick;

            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
  }

  onImageClick = (e) => {

    const latency = (Date.now() - this.state.dispTime) / 1000;
    this.setState({
      results: [
        ...this.state.results,
        {
          displayImage: this.state.pairs[this.state.currentIteration].displayImage,
          responseImage: this.state.pairs[this.state.currentIteration].responseImage,
          latency,
          quadrant: this.state.pairs[this.state.currentIteration].quadrant,
          iteration: this.state.currentIteration
        },
      ],
    })
    document.getElementById("user_img").style.display = "none"

    switch (this.state.pairs[this.state.currentIteration].quadrant) {
      case 1:
        let responseImage = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].responseImage}" id="resp_img">`
        document.getElementById("first_quad").innerHTML = responseImage
        document.getElementById("second_quad").innerHTML = ""
        document.getElementById("third_quad").innerHTML = ""
        document.getElementById("fourth_quad").innerHTML = ""

        break;
      case 2:
        let responseImage2 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].responseImage}" id="resp_img">`
        document.getElementById("first_quad").innerHTML = ""
        document.getElementById("second_quad").innerHTML = responseImage2
        document.getElementById("third_quad").innerHTML = ""
        document.getElementById("fourth_quad").innerHTML = ""
        break;
      case 3:
        let responseImage3 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].responseImage}" id="resp_img">`
        document.getElementById("first_quad").innerHTML = ""
        document.getElementById("second_quad").innerHTML = ""
        document.getElementById("third_quad").innerHTML = responseImage3
        document.getElementById("fourth_quad").innerHTML = ""
        break;
      case 4:
        let responseImage4 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].responseImage}" id="resp_img">`
        document.getElementById("first_quad").innerHTML = ""
        document.getElementById("second_quad").innerHTML = ""
        document.getElementById("third_quad").innerHTML = ""
        document.getElementById("fourth_quad").innerHTML = responseImage4
        break;

      default:
        break;
    }

    setTimeout(() => {
      this.setState({
        dispTime: Date.now(),
        currentIteration: this.state.currentIteration + 1
      }, () => {
        if (this.state.currentIteration >= this.state.iterations) {
          // console.log(this.state.latency);
          console.log("Game Over");
          document.getElementById("tableWrapper").style.display = "none"
  
          const sum = this.state.results.reduce((accumulator, item) => accumulator + item.latency, 0)
          const averageLatency = sum / this.state.results.length
          this.setState({
            average: averageLatency
          }, () => {
            document.getElementById("step3_btn").style.display = "block"
            document.getElementById("step2_instructions").innerHTML = `<h5>Excellent, keep going!</h5>`
            document.getElementById("step2_instructions").style.display = "block"
    
            document.getElementById("results").innerHTML = `</br><strong class="lead">Average response time = ${this.state.average.toFixed(3)} sec</strong>`
    
          })
          
          this.handleSync({
            status: "STEP_2_COMPLETED",
          })

  
        } else {
          if(evaluateBreakPoint(this.state.currentIteration, this.state.breakPoint)) {
            this.showBreakPoint();
          }
          if(evaluateBreakPoint(this.state.currentIteration, SYNC_INTERVAL)) {
            this.handleSync();
          }
  
          document.getElementById("first_quad").innerHTML = ""
  
          switch (this.state.pairs[this.state.currentIteration].quadrant) {
            case 1:
              let image = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
              document.getElementById("first_quad").innerHTML = image
              document.getElementById("second_quad").innerHTML = ""
              document.getElementById("third_quad").innerHTML = ""
              document.getElementById("fourth_quad").innerHTML = ""
  
              document.getElementById('user_img').onclick = this.onImageClick;
              break;
  
            case 2:
              let image2 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
              document.getElementById("first_quad").innerHTML = ""
              document.getElementById("second_quad").innerHTML = image2
              document.getElementById("third_quad").innerHTML = ""
              document.getElementById("fourth_quad").innerHTML = ""
              document.getElementById('user_img').onclick = this.onImageClick;
              break;
            case 3:
  
              let image3 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
              document.getElementById("first_quad").innerHTML = ""
              document.getElementById("second_quad").innerHTML = ""
              document.getElementById("third_quad").innerHTML = image3
              document.getElementById("fourth_quad").innerHTML = ""
              document.getElementById('user_img').onclick = this.onImageClick;
              break;
            case 4:
              let image4 = `<img src="${process.env.REACT_APP_STATIC_URL}/${this.state.pairs[this.state.currentIteration].displayImage}" id="user_img">`
              document.getElementById("first_quad").innerHTML = ""
              document.getElementById("second_quad").innerHTML = ""
              document.getElementById("third_quad").innerHTML = ""
              document.getElementById("fourth_quad").innerHTML = image4
              document.getElementById('user_img').onclick = this.onImageClick;
              break;
  
            default:
              console.log("Invalid Value");
              break;
          }
        }
      })
    }, this.state.flashTime);

  }

  render() {
    return (
      <div>
        <UserHeader />
        <div className="userStepTwoPage container text-center p-4">
          {/* <h1 className="display-4 text-center text-info">STEP 2</h1> */}
          <div className="text-center">
            <span className="text-danger">{this.state.error}</span>
          </div>
          <div className="stepTwoInstructions text-center" id="step2_instructions">
            <p>{this.state.instructions}</p>
          </div>
          <div className="stepOneInstructions text-center" id="breakInstructions">
          </div>
          <button
            className="btn btn-info mt-4 py-2 px-3 mx-auto"
            id="breakResumeButton"
            onClick={this.resumeBreak.bind(this)}
          >
            <h4 className="mb-0">Resume!</h4>
          </button>
          <div className="text-center" id="results"></div>
          { 
            this.state.instructions && 
              <button className="btn btn-info startButton2 mt-5" id="startButton2" onClick={this.startStep2}>
                <h4 className="mb-0">Resume!</h4>
              </button>
          }
          <div className="tableWrapper" id="tableWrapper">
            <table className="quadrantTable w-100">
              <tbody>
                <tr>
                  <td id="first_quad">
                  </td>
                  <td id="second_quad">
                  </td>
                </tr>
                <tr>
                  <td id="third_quad">
                  </td>
                  <td id="fourth_quad">
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <button className="btn btn-info px-5 mt-5" id="step3_btn" onClick={this.onClickNext.bind(this)}>
            Next
          </button>
        </div>
      </div>
    );
  }

  onClickNext(e) {
    // console.log("Next Clicked");
    this.props.history.push("/step3",
      {
      }
    );
  }
}
export default UserStep2;
