import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

class UsersPassword extends Component {
    state = {
        username: "",
        newPassword: "",
        confirmPassword: "",
        errorMessage: "",
        successMessage: "",
    };

    componentDidMount() {
        //   Check Authorization
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.props.history.push("/admin");
                }
            })
            .catch((error) => {
                return console.log(error);
            });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    //   Submit Handler

    onSubmitHandler = (e) => {
        e.preventDefault();

        // console.log(this.state);
        Axios.put(process.env.REACT_APP_BASE_URL + "/admin/change-password/users", this.state, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((result) => {
                this.setState({
                    newPassword: "",
                    confirmPassword: "",
                })
                // console.log(result.data);
                if (result.data.error) {
                    this.setState({
                        errorMessage: result.data.error,
                        successMessage: "",
                    });
                }
                if (result.data.message) {
                    this.setState({
                        successMessage: result.data.message,
                        errorMessage: "",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        return (
            <div>
                <Header />
                <SideNav />
                <div className="passwordPage">
                    <h3 className="text-center mt-5">Change User's Password</h3>
                    <div className="text-center bg-light passwordFiledsWrapper">
                        <span className="text-danger">
                            {this.state.errorMessage ? this.state.errorMessage : ""}
                        </span>

                        <span className="text-success">
                            {this.state.successMessage ? this.state.successMessage : ""}
                        </span>
                        <form onSubmit={this.onSubmitHandler.bind(this)}>
                            <div className="form-group">
                                <label className="float-left">Username</label>
                            </div>
                            <div className="form-group">
                                <input
                                    name="username"
                                    onChange={this.onChangeHandler.bind(this)}
                                    value={this.state.username}
                                    type="text"
                                ></input>
                            </div>
                            <div className="form-group">
                                <label className="float-left">New Password</label>
                            </div>
                            <div className="form-group">
                                <input
                                    name="newPassword"
                                    onChange={this.onChangeHandler.bind(this)}
                                    value={this.state.newPassword}
                                    type="password"
                                ></input>
                            </div>
                            <div className="form-group">
                                <label className="float-left">Confirm Password</label>
                            </div>
                            <div className="form-group">
                                <input
                                    name="confirmPassword"
                                    onChange={this.onChangeHandler.bind(this)}
                                    value={this.state.confirmPassword}
                                    type="password"
                                ></input>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-info float-right">
                                    Change
                                 </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default UsersPassword;
