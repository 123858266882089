import Axios from 'axios';
import React, { Component } from 'react'
import Header from '../Header';
import SideNav from '../SideNav';
import { v1 as uuid } from "uuid";

class AddLabel extends Component {

    state = {
        version: "",
        numOfLabels: 1,

        leftLabels: [],
        rightLabels: [],

        leftTags: [],
        leftTags1: [],
        rightTags: [],
        rightTags1: [],

        leftCategories: [],
        rightCategories: [],

        leftColors: {},
        rightColors: {},

        iterations: "",
        instructions: "",
        categories: "",
        allCategories: [],

        errors: "",
        success: "",

    }

    componentDidMount() {

        const { id, version } = this.props.match.params
        this.setState({
            version
        })
        //   Check Authorization
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            },
        })
            .then((res) => {
                if (res.data.error) {
                    this.props.history.push("/admin");
                } else {

                    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/provide/all-categories", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
                        },
                    }).then(result => {
                        if (result.data.error) {
                            this.setState({
                                errors: result.data.error
                            })
                        }
                        if (result.data.catNames) {
                            this.setState({
                                allCategories: result.data.catNames,
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                    })

                    Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/labels/label", {
                        params: { _id: id, version: version },
                        headers: { Authorization: "Bearer " + localStorage.getItem("admin-access-token") }
                    }).then(result => {
                        if (result.data.error) {
                            this.setState({
                                errors: result.data.error,
                                success: ""
                            })
                        } if (result.data.label) {
                            // console.log(result.data.label);
                            this.setState({
                                numOfLabels: result.data.label.numOfLabels,
                                leftLabels: result.data.label.leftLabels[0],
                                rightLabels: result.data.label.rightLabels[0],
                                leftTags: result.data.label.leftTags[0],
                                leftTags1: result.data.label.leftTags[1],
                                rightTags: result.data.label.rightTags[0],
                                rightTags1: result.data.label.rightTags[1],
                                leftCategories: result.data.label.leftCategories[0],
                                rightCategories: result.data.label.rightCategories[0],
                                leftColors: result.data.label.leftColors[0],
                                rightColors: result.data.label.rightColors[0],
                                iterations: result.data.label.iterations,
                                instructions: result.data.label.instructions,
                                errors: "",
                                success: ""
                            }, () => {

                                if (this.state.leftTags.find(item => item === "words")) {
                                    document.getElementById("leftWrd0").checked = "true"
                                }
                                if (this.state.leftTags1.find(item => item === "words")) {
                                    document.getElementById("leftWrd1").checked = "true"
                                }
                                if (this.state.rightTags.find(item => item === "words")) {
                                    document.getElementById("rightWrd0").checked = "true"
                                }
                                if (this.state.rightTags1.find(item => item === "words")) {
                                    document.getElementById("rightWrd1").checked = "true"
                                }



                                if (this.state.leftTags.find(item => item === "images")) {
                                    document.getElementById("leftImg0").checked = "true"
                                }
                                if (this.state.leftTags1.find(item => item === "images")) {
                                    document.getElementById("leftImg1").checked = "true"
                                }
                                if (this.state.rightTags.find(item => item === "images")) {
                                    document.getElementById("rightImg0").checked = "true"
                                }
                                if (this.state.rightTags1.find(item => item === "images")) {
                                    document.getElementById("rightImg1").checked = "true"
                                }
                            })
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                return console.log(error);
            });
    }

    onchangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onNumOfLabelsChange = e => {
        this.setState({
            errors: ""
        })
        if (e.target.value > 2) {
            this.setState({
                errors: "Should be max 2 labels"
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value,

            })

        }
    }

    onCheckboxClick = (i, e) => {

        if (i === 0) {
            if (e.target.name === "leftTags") {
                if (e.target.checked) {
                    let arr = this.state.leftTags;
                    arr.push(e.target.value)
                    this.setState({
                        leftTags: arr
                    })
                } else {
                    let arr = this.state.leftTags;
                    let fArray = arr.filter(item => item !== e.target.value)
                    this.setState({
                        leftTags: fArray
                    })
                }
            }

            if (e.target.name === "rightTags") {
                if (e.target.checked) {
                    let arr = this.state.rightTags;
                    arr.push(e.target.value)
                    this.setState({
                        rightTags: arr
                    })
                } else {
                    let arr = this.state.rightTags;
                    let fArray = arr.filter(item => item !== e.target.value)
                    this.setState({
                        rightTags: fArray
                    })
                }
            }
        } else if (i === 1) {
            if (e.target.name === "leftTags") {
                if (e.target.checked) {
                    let arr = this.state.leftTags1;
                    arr.push(e.target.value)
                    this.setState({
                        leftTags1: arr
                    })
                } else {
                    let arr = this.state.leftTags1;
                    let fArray = arr.filter(item => item !== e.target.value)
                    this.setState({
                        leftTags1: fArray
                    })
                }
            }

            if (e.target.name === "rightTags") {
                if (e.target.checked) {
                    let arr = this.state.rightTags1;
                    arr.push(e.target.value)
                    this.setState({
                        rightTags1: arr
                    })
                } else {
                    let arr = this.state.rightTags1;
                    let fArray = arr.filter(item => item !== e.target.value)
                    this.setState({
                        rightTags1: fArray
                    })
                }
            }
        }
    }
    onColorChange = (i, e) => {
        if (e.target.name === "leftColors") {
            this.setState({
                leftColors: { ...this.state.leftColors, [i]: e.target.value }
            })
        }
        if (e.target.name === "rightColors") {
            this.setState({
                rightColors: { ...this.state.rightColors, [i]: e.target.value }
            })
        }
    }
    onFormSubmit = (e) => {
        e.preventDefault()
        this.setState({
            errors: "",
            success: ""
        })
        let leftTags = [this.state.leftTags, this.state.leftTags1]
        let rightTags = [this.state.rightTags, this.state.rightTags1]
        Axios.put(process.env.REACT_APP_BASE_URL + "/admin/game2/labels/update-label",
            {
                version: this.state.version,
                numOfLabels: this.state.numOfLabels,
                rightLabels: this.state.rightLabels,
                leftLabels: this.state.leftLabels,
                leftTags: leftTags,
                rightTags: rightTags,
                leftCategories: this.state.leftCategories,
                rightCategories: this.state.rightCategories,
                leftColors: this.state.leftColors,
                rightColors: this.state.rightColors,
                iterations: this.state.iterations,
                instructions: this.state.instructions
            },
            {
                params: {
                    _id: this.props.match.params.id, version: this.props.match.params.version
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("admin-access-token")
                }
            }).then(result => {
                if (result.data.error) {
                    this.setState({
                        errors: result.data.error
                    })
                } if (result.data.message) {
                    this.setState({
                        success: result.data.message
                    })
                }
            }).catch(error => {
                console.log(error);
            })
    }

    onLabelsChange = (i, e) => {
        if (e.currentTarget.name === "leftLabels") {
            this.setState({
                leftLabels: { ...this.state.leftLabels, [i]: e.currentTarget.value }
            })
        }
        if (e.currentTarget.name === "rightLabels") {
            this.setState({
                rightLabels: { ...this.state.rightLabels, [i]: e.currentTarget.value }
            })
        }
    }
    onCategoryChange = (i, e) => {
        if (e.target.name === "leftCategories") {
            this.setState({
                leftCategories: { ...this.state.leftCategories, [i]: e.target.value }
            })
        }
        if (e.target.name === "rightCategories") {
            this.setState({
                rightCategories: { ...this.state.rightCategories, [i]: e.target.value }
            })
        }
    }
    render() {

        let leftFields = []
        for (let i = 0; i < this.state.numOfLabels; i++) {
            leftFields.push(
                <div key={i}>
                    {/* Left Label */}
                    <label className="mt-4">Left Label</label>
                    <div className="form-group">
                        <input className="form-control" name="leftLabels"
                            onChange={this.onLabelsChange.bind(this, i)}
                            value={this.state.leftLabels[i]}
                            type="text" placeholder="Add Label to Left"></input>

                    </div>
                    <br />
                    {/* Left Categories */}
                    <div className="form-group">
                        <label>Left Category</label>
                        <select className="form-control" name="leftCategories"
                            value={this.state.leftCategories[i]} onChange={this.onCategoryChange.bind(this, i)}>
                            <option>-Select left Category-</option>
                            <option value="user">User Specific</option>
                            {this.state.allCategories.map(name => (
                                <option key={uuid()} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <label>Select Tags</label>
                    <div className="form-group">

                        <div className="dropdown">
                            <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {i === 0 && this.state.leftTags.length > 0
                                    ? (this.state.leftTags.map(item => `${item}, `))
                                    : i === 1 && this.state.leftTags1.length > 0
                                        ? (this.state.leftTags1.map(item => `${item}, `))
                                        : ("Nothing selected")}

                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item">
                                    <input type="checkbox" id={`leftImg${i}`} name="leftTags"
                                        value="images" onChange={this.onCheckboxClick.bind(this, i)} />{" "}
                                    <label className="ml-3"> Images </label>
                                </div>
                                <div className="dropdown-item">
                                    <input type="checkbox" id={`leftWrd${i}`} name="leftTags"
                                        value="words" onChange={this.onCheckboxClick.bind(this, i)} />{" "}
                                    <label className="ml-3"> Words </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="form-group">
                        <label>Select Color</label>
                        <input className="form-control" type="color" name="leftColors"
                            onChange={this.onColorChange.bind(this, i)} value={this.state.leftColors[i]}></input>
                    </div>
                    <br />
                    <hr className="bg-info" />
                </div>
            )
        }

        let rightFields = []
        for (let i = 0; i < this.state.numOfLabels; i++) {

            rightFields.push(
                <div key={i}>
                    {/* Right Label */}
                    <label className="mt-4">Right Label</label>
                    <div className="form-group">
                        <input className="form-control" name="rightLabels"
                            value={this.state.rightLabels[i]}
                            onChange={this.onLabelsChange.bind(this, i)}
                            type="text" placeholder="Add Label to Right">
                        </input>
                    </div>
                    <br />
                    {/* Right Categories */}
                    <div className="form-group">
                        <label>Right Category</label>
                        <select className="form-control" name="rightCategories"
                            value={this.state.rightCategories[i]} onChange={this.onCategoryChange.bind(this, i)}>
                            <option>-Select Right Category-</option>
                            <option value="user">User Specific</option>
                            {this.state.allCategories.map(name => (
                                <option key={uuid()} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <label>Select Tags</label>
                    <div className="form-group">
                        <div className="dropdown">
                            <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {i === 0 && this.state.rightTags.length > 0
                                    ? (this.state.rightTags.map(item => `${item}, `))
                                    : i === 1 && this.state.rightTags1.length > 0
                                        ? (this.state.rightTags1.map(item => `${item}, `))
                                        : ("Nothing selected")}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item">
                                    <input type="checkbox" id={`rightImg${i}`} name="rightTags"
                                        value="images" onChange={this.onCheckboxClick.bind(this, i)} />{" "}
                                    <label className="ml-3"> Images </label>
                                </div>
                                <div className="dropdown-item">
                                    <input type="checkbox" id={`rightWrd${i}`} name="rightTags"
                                        value="words" onChange={this.onCheckboxClick.bind(this, i)} />{" "}
                                    <label className="ml-3"> Words </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="form-group">
                        <label>Select Color</label>
                        <input className="form-control" type="color" name="rightColors"
                            onChange={this.onColorChange.bind(this, i)} value={this.state.rightColors[i]}></input>
                    </div>
                    <br />
                    <hr className="bg-info" />

                </div>
            )
        }

        return (
            <div className="game2AddLabelPage">
                <Header />
                <SideNav />
                <div >
                    <div className="container">
                        <form onSubmit={this.onFormSubmit}>
                            <h3 className="text-dark text-center">Update Label</h3>

                            <div className="text-center text-danger">{this.state.errors}</div>
                            <div className="text-center text-success">{this.state.success}</div>

                            <div className="form-group w-50 m-auto pt-5">
                                <label>Version</label>
                                <select className="form-control" name="version" value={this.state.version}
                                    onChange={this.onchangeHandler} disabled>
                                    <option value="" disabled>-SELECT VERSION-</option>
                                    <option value="1">Version 1</option>
                                    <option value="2">Version 2</option>

                                </select>
                                <br />
                                <label>Num of labels</label>
                                <input className="form-control" type="number" max="2" min="1"
                                    name="numOfLabels" value={this.state.numOfLabels}
                                    onChange={this.onNumOfLabelsChange} disabled></input>
                            </div>
                            <div className="row container w-75 m-auto pt-3">
                                <div className="col-6">
                                    <h4 className="text-center text-info">Left</h4>
                                    {leftFields.map(fields => fields)}

                                </div>

                                <div className="col-6">
                                    <h4 className="text-center text-info">Right</h4>
                                    {rightFields.map(fields => fields)}

                                </div>
                            </div>
                            {/* <hr className="w-75 bg-info mt-5" /> */}
                            <div className="w-50 m-auto">
                                <label className="mt-4">Iterations</label>
                                <div className="form-group">

                                    {/* Iterations */}
                                    <input type="number" name="iterations" min="1" onChange={this.onchangeHandler}
                                        className="form-control" value={this.state.iterations}
                                        placeholder="Set Num of Iterations"></input>

                                </div>
                                <label className="mt-4">Add Instructions</label>
                                <div className="form-group">

                                    {/* Instructions */}
                                    <textarea type="" name="instructions" onChange={this.onchangeHandler} className="form-control"
                                        value={this.state.instructions} placeholder="Instructions..." rows="5"></textarea>

                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button className="btn btn-info w-50">Update This Block</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}
export default AddLabel;