import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";

// Admin Components

import AdminLogin from "./components/AdminComponents/AdminLogin";
import AdminDashboard from "./components/AdminComponents/AdminDashboard";
import UploadUsers from "./components/AdminComponents/UploadsUsers";
import DisplayUserImages from "./components/AdminComponents/DisplayUserImages"
import DisplayUserWords from './components/AdminComponents/DisplayUserWords'
import Uploads from "./components/AdminComponents/UploadsGenerals";
import DisplayImages from "./components/AdminComponents/DisplayImages";
import DisplayWords from "./components/AdminComponents/DisplayWords";
import AllUsers from "./components/AdminComponents/AllUsers";
import AdminUsers from "./components/AdminComponents/AdminUsers";
import SuspendedUsers from "./components/AdminComponents/SuspendedUsers";
import RegisterUser from "./components/AdminComponents/registerUser";
import Category from "./components/AdminComponents/AddCategory";
import Password from "./components/AdminComponents/Password";
import Instructions from "./components/AdminComponents/Instructions"
import Step1A from "./components/AdminComponents/Step1A";
import Step1B from "./components/AdminComponents/Step1B";

import Step2 from "./components/AdminComponents/Step2";
import Step3 from "./components/AdminComponents/Step3";
import Categories from "./components/AdminComponents/Game2/Categories";
import AllLabels from "./components/AdminComponents/Game2/AllLabels";
import AddLabel from "./components/AdminComponents/Game2/AddLabel";
import UpdateLabel from "./components/AdminComponents/Game2/UpdateLabel"
import Upload from "./components/AdminComponents/Game2/Upload";
import Game2DisplayWords from "./components/AdminComponents/Game2/Game2DisplayWords"
import Game2DisplayImages from "./components/AdminComponents/Game2/Game2DisplayImages"
import UsersPassword from "./components/AdminComponents/UsersPassword";

// User Components

import Login from "./components/UserComponents/Login";
import Game2Login from "./components/UserComponents/UserGame2/Game2Login";
import UserStep1A from "./components/UserComponents/UserStep1A";
import UserStep1B from "./components/UserComponents/UserStep1B";
import UserStep2 from "./components/UserComponents/UserStep2";
import UserStep3 from "./components/UserComponents/UserStep3";
import UserGame2 from "./components/UserComponents/UserGame2/UserGame2";
import AdminEditForm from "./components/AdminComponents/AdminEditForm";
import { jwtDecode } from "jwt-decode";
import BreakPointInstructions from "./components/AdminComponents/BreakPointInstructions";

export default class App extends Component {

  state = {
    isSuperAdmin: false,
  };
  componentDidMount() {
    this.setState({
      isSuperAdmin: localStorage.getItem("admin-access-token") && jwtDecode(localStorage.getItem("admin-access-token")).role === "SUPER_ADMIN" 
    });
  }
  render() {
    return (
      <div>
        <BrowserRouter>

          {/* Admin Routes */}

          <Route path="/admin" exact component={AdminLogin} />
          <Route path="/admin/admin-dashboard" component={AdminDashboard} />
          <Route path="/admin/upload-user" component={UploadUsers} />

          <Route path="/admin/user-images" component={DisplayUserImages}></Route>
          <Route path="/admin/user-words" component={DisplayUserWords}></Route>
          <Route path="/admin/create-user" component={RegisterUser} />
          <Route path="/admin/all-users" component={AllUsers} />
          <Route path="/admin/suspended-users" component={SuspendedUsers} />
          <Route path="/admin/change-users-password" component={UsersPassword} />
          <Route path="/admin/change-password" component={Password} />

          {
            this.state.isSuperAdmin &&
            <>
              <Route exact path="/admin/users/create" component={AdminEditForm} />
              <Route exact  path="/admin/users/edit/:id" component={AdminEditForm} />
              <Route exact path="/admin/users" component={AdminUsers} />
              <Route path="/admin/display-images" component={DisplayImages} />
              <Route path="/admin/display-words" component={DisplayWords} />
              <Route path="/admin/upload-general" component={Uploads} />
              <Route path="/admin/categories" component={Category} />
              <Route exact path="/admin/instructions/break" component={BreakPointInstructions} />
              <Route exact path="/admin/instructions" component={Instructions} />

              <Route path="/admin/step1A" component={Step1A} />
              <Route path="/admin/step1B" component={Step1B} />
              <Route path="/admin/step2" component={Step2} />
              <Route path="/admin/step3" component={Step3} />
              <Route path="/admin/game2/categories" component={Categories} />
              <Route path="/admin/game2/all-label" component={AllLabels} />
              <Route path="/admin/game2/add-label" component={AddLabel} />
              <Route path="/admin/game2/update-label/:id/:version" component={UpdateLabel}></Route>
              <Route path="/admin/game2/upload" component={Upload}></Route>
              <Route path="/admin/game2/display/images" component={Game2DisplayImages}></Route>
              <Route path="/admin/game2/display/words" component={Game2DisplayWords}></Route>
            </>
          }

          {/* User Routes */}

          <Route path="/game1" exact component={Login}></Route>
          <Route path="/game2" exact component={Game2Login}></Route>
          <Route path="/step1A" component={UserStep1A}></Route>
          <Route path="/step1B" component={UserStep1B}></Route>
          <Route path="/step2" component={UserStep2}></Route>
          <Route path="/step3" component={UserStep3}></Route>
          <Route path="/play-game2" component={UserGame2}></Route>

        </BrowserRouter>
      </div>
    );
  }
}
