import React, { Component } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Axios from "axios";
import { v1 as uuid } from "uuid";

class Step1 extends Component {
  state = {
    stepThree: null,
    userType: "",
    isActive: false,
    flashTime: "",
    pairs: "",
    numOfTimes: "",
    numOfMatrices: "",
    errorMessages: "",
    successMessages: "",
    allWordCategories: [],

    // Dynamic Input Fields
    selectUserOrGeneral: [],
    selectCategory: [],
    selectGeneralCategory: [],

    // Options 
    generalCatOptions: [],
    userOrGeneralCatOptions: [],

    // result flash time
    resultFlashTime: ""
  }

  componentDidMount() {

    if (!this.state.userType) {
      this.setState({
        errorMessages: "Select User Type first"
      })
      document.getElementById("flashAndPairs").style.display = "none"
      document.getElementById("fieldsArray").style.display = "none"
    }
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        } else {
          Axios.get(
            process.env.REACT_APP_BASE_URL + "/admin/providing/all-image-categories",
            {
              headers: {
                Authorization:
                  "Bearer " + localStorage.getItem("admin-access-token"),
              },
            }
          )
            .then((result) => {
              this.setState({
                generalCatOptions: result.data,
              });

              Axios.get(process.env.REACT_APP_BASE_URL + "/admin/providing/all-word-categories",
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("admin-access-token"),
                  },
                }).then(result => {
                  this.setState({
                    allWordCategories: result.data
                  })
                }).catch(error => {
                  console.log(error);
                })
            })
            .catch((error) => console.log(error));

            Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/step3/settings/", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
              }
            }).then(result => {
              this.setState({
                stepThree: result.data,
                isActive: result.data.isActive,
              })
            }).catch(error => {
              console.log(error);
            })
        }
      }).catch(error => {
        console.log(error);
      })


  }

  onChangeHandler = e => {


    if (e.target.name === "userType") {
      const { stepThree } = this.state;

      let settings = e.target.value === "A"
      ? stepThree?.userA
      : e.target.value === "B"
          ? stepThree?.userB
          : null

      if(settings) {
        this.setState({
          flashTime: settings.flashTime,
          pairs: settings.numOfPairs,
          numOfTimes: settings.numOfTimes,
          numOfMatrices: settings.numOfMatrices,
          selectUserOrGeneral: settings.imagesTypes,
          // userOrGeneralCatOptions: settings.imagesTypes,
          selectCategory: settings.firstPair,
          selectGeneralCategory: settings.secondPair,
          resultFlashTime: settings.resultFlashTime
        })
        let userOrGeneralCatOptions = {}; 
        // console.log(settings.imagesTypes[0]);
        for (let i = 0; i < settings.numOfPairs; i++) {
          if (settings.imagesTypes[i] === "user") {
            userOrGeneralCatOptions = { ...userOrGeneralCatOptions, [i]: "" }
          }
          if (settings.imagesTypes[i] === "general") {
            userOrGeneralCatOptions = { ...userOrGeneralCatOptions, [i]: this.state.generalCatOptions }
          }
        }
        this.setState({
          userOrGeneralCatOptions
        })
      }
    }

    if (e.target.name === "pairs") {
      this.setState({
        selectUserOrGeneral: [],
        selectCategory: [],
        selectGeneralCategory: []
      })
    }

    this.setState({
      [e.target.name]: e.target.value
    })

    this.setState({
      errorMessages: ""
    })

  }

  onSubmitHandler = e => {

    e.preventDefault();
    console.log(this.state);
    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/steps/step3/settings", this.state, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      }
    }).then(result => {
      if (result.data.error) {
        this.setState({
          errorMessages: result.data.error,
          successMessages: ""
        })
      } if (result.data.message) {

        this.setState({
          successMessages: result.data.message,
          errorMessages: ""
        })
      }
    }).catch(error => {
      console.log(error);
    })

  }
  selectUserOrGeneralChange = (i, e) => {
    if (e.target.value === "user") {

      this.setState({
        userOrGeneralCatOptions: { ...this.state.userOrGeneralCatOptions, [i]: "" },

        selectCategory: { ...this.state.selectCategory, [i]: "user" }
      })
      // document.getElementById(i).disabled = true

    }
    if (e.target.value === "general") {

      this.setState({
        userOrGeneralCatOptions: { ...this.state.userOrGeneralCatOptions, [i]: this.state.generalCatOptions }
      })

    }

    this.setState({
      selectUserOrGeneral: { ...this.state.selectUserOrGeneral, [i]: e.target.value }
    })

  }

  selectCategoryChange = (i, e) => {
    // console.log(e.target.value);
    this.setState({
      selectCategory: { ...this.state.selectCategory, [i]: e.target.value }
    })
  }

  selectGeneralCategoryChange = (i, e) => {
    this.setState({
      selectGeneralCategory: { ...this.state.selectGeneralCategory, [i]: e.target.value }
    })
  }
  render() {

    if (this.state.userType !== "") {
      document.getElementById("flashAndPairs").style.display = "block"
      document.getElementById("fieldsArray").style.display = "block"
    }

    let fieldsArr = [];
    for (let i = 0; i < this.state.pairs; i++) {
      fieldsArr.push(
        <div key={uuid()} className="row">
          <div className="col-2">
            <label>Select</label>
            <div className="form-group ">
              <select className="form-control" onChange={this.selectUserOrGeneralChange.bind(this, i)} value={this.state.selectUserOrGeneral[i]}>
                <option value="" className="text-secondary">-User / General-</option>
                <option value="user">User</option>
                <option value="general">General</option>
              </select>
            </div>
          </div>

          <div className="col-5">
            <label>Category</label>
            <select className="form-control" onChange={this.selectCategoryChange.bind(this, i)} value={this.state.selectCategory[i]} id={i} >
              <option value={this.state.userOrGeneralCatOptions[i] === "general" ? "" : "user"} className="text-secondary">
                -Select Category-
              </option>
              <option className="text-success" disabled>--Images Categories--</option>
              {this.state.userOrGeneralCatOptions[i] ? this.state.userOrGeneralCatOptions[i].map(cat => (
                <option key={uuid()} value={cat}>{cat}</option>
              )) : []}
              <option className="text-success" disabled>--Word Categories--</option>
              {this.state.userOrGeneralCatOptions[i] ? this.state.allWordCategories.map(cat => (
                <option key={uuid()} value={cat}>{cat}</option>
              )) : []}
            </select>
          </div>

          <div className="col-5">
            <label>General Category</label>
            <select className="form-control" onChange={this.selectGeneralCategoryChange.bind(this, i)} value={this.state.selectGeneralCategory[i]}>
              <option value="" className="text-secondary">-Select General Category-</option>
              <option className="text-success" disabled>--Image Categories--</option>

              {this.state.generalCatOptions.map(cat => (
                <option key={uuid()} value={cat}>{cat}</option>
              ))}
              <option className="text-success" disabled>--Word Categories--</option>
              {this.state.allWordCategories.map(cat => (
                <option key={uuid()} value={cat}>{cat}</option>
              ))}

            </select>
          </div>
        </div>
      )
    }
    return (
      <div>
        <Header />
        <SideNav />
        <div className="stepThreePage">
          <h1 className="text-info text-center display-4">STEP : 3</h1>
          <div className="container mt-4">

            <form className="w-100 m-auto" onSubmit={this.onSubmitHandler}>

              <div className="text-center">
                <span className="text-danger">{this.state.errorMessages}</span>
                <span className="text-success">{this.state.successMessages}</span>
              </div>

              <div className="form-group w-50 m-auto">
                <label>Active/Inactive</label>
                <select name="isActive" className="form-control"
                  value={this.state.isActive} onChange={this.onChangeHandler}>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
              <br />

              <div className="form-group w-50 m-auto">
                <label>User Type</label>
                <select name="userType" className="form-control"
                  value={this.state.userType} onChange={this.onChangeHandler}>
                  <option value="" disabled>-Select User Type-</option>
                  <option value="A">Active</option>
                  <option value="B">Sham</option>
                </select>
              </div>

              <div className="w-50 m-auto" id="flashAndPairs">
                <br />
                <label>Set Flash Time (<span className="text-secondary">in Milliseconds</span>)</label>
                <div className="form-group">

                  <input name="flashTime" type="number" onChange={this.onChangeHandler} value={this.state.flashTime}
                    placeholder="Flash Time" min="100" className="form-control "></input>

                </div>


                <label>Set Result Delay (<span className="text-secondary">in Milliseconds</span>)</label>
                <div className="form-group">
                  <input name="resultFlashTime" type="number" onChange={this.onChangeHandler} value={this.state.resultFlashTime}
                    placeholder="Result Delay" min="100" className="form-control "></input>

                </div>

                <div className="form-group">
                  <label>No. of Pair(s) </label>
                  <input name="pairs" onChange={this.onChangeHandler} type="number" value={this.state.pairs}
                    placeholder="Set Number of Pairs" min="1" className="form-control "></input>

                </div>

                <div className="form-group">
                  <label>No. of Times (<span className="text-secondary">User can play</span>)</label>
                  <input name="numOfTimes" onChange={this.onChangeHandler} value={this.state.numOfTimes} type="number"
                    placeholder="Enter Number of Times user can play" min="1" className="form-control"></input>
                </div>

                <div className="form-group">
                  <label>No. of Matrices</label>
                  <input name="numOfMatrices" onChange={this.onChangeHandler} value={this.state.numOfMatrices} type="number"
                    placeholder="Enter Number of Matrices" min="1" className="form-control"></input>
                </div>

              </div>
              <div id="fieldsArray">

                {fieldsArr.map(field => (field))}
              </div>
              <br />
              <br />
              <div className="form-group text-center">
                <button className="btn btn-info w-50" type="submit">Save Settings</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Step1;
