import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SideNav() {
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  
  useEffect(() => {
    setSuperAdmin(
      jwtDecode(localStorage.getItem("admin-access-token")).role === "SUPER_ADMIN"
    )
  }, [])

  return (
    <div className="sideNav bg-dark text-info">
      <h1 className="text-warning appLogo">PittsApp</h1>
      <ul>

        {/* User */}

        <li>
          <Link className="text-info" to="/admin/upload-user">
            <i className="fas fa-upload px-3"></i> User
          </Link>
        </li>
        <li>
          <Link className="text-info" to="/admin/user-images">
            <i className="fas fa-image px-3"></i> User Images
          </Link>
        </li>
        <li>
          <Link className="text-info" to="/admin/user-words">
            <i className="fas fa-file px-3"></i> User Words
          </Link>
        </li>
        <li>
          <Link to="/admin/create-user" className="text-info">
            <i className="fas fa-user-plus px-3"></i>Create User
          </Link>
        </li>

        <li>
          <Link to="/admin/change-users-password" className="text-info">
            <i className="fas fa-user-shield px-3"></i>User's Password
          </Link>
        </li>
        <li>
          <Link to="/admin/all-users" className="text-info">
            <i className="fas fa-users px-3"></i>Registered Users
          </Link>
        </li>
        <li>
          <Link to="/admin/suspended-users" className="text-info">
            <i className="fas fa-ban px-3 text-danger"></i>Suspended Users
          </Link>
        </li>
        {
          isSuperAdmin && 
          <li>
            <Link to="/admin/users" className="text-info">
              <i className="fas fa-user px-3"></i>Admin Users
            </Link>
          </li>
        }
        <li>
          <Link to="/admin/change-password" className="text-info">
            <i className="fas fa-user-shield px-3"></i>Password
          </Link>
        </li>
        {/* Game 1 */}

        <hr className="bg-info mr-4 ml-2 mb-0" />
        {
          isSuperAdmin && 
          <>
            <li className="text-light ml-5" style={{ cursor: "default" }}>
              {/* <i className="fas fa-gamepad px-3" ></i> */}
              GAME 1
            </li>
            <li>
              <Link className="text-info" to="/admin/upload-general">
                <i className="fas fa-upload px-3"></i> General
              </Link>
            </li>
            <li>
              <Link to="/admin/display-images" className="text-info">
                <i className="fas fa-image px-3"></i>General Images
              </Link>
            </li>
            <li>
              <Link to="/admin/display-words" className="text-info">
                <i className="fas fa-file px-3"></i>General Words
              </Link>
            </li>
            <li>
              <Link to="/admin/categories" className="text-info">
                <i className="fas fa-sitemap px-3"></i>Categories
              </Link>
            </li>

            <li>
              <Link to="/admin/instructions" className="text-info">
                <i className="fas fa-info-circle px-3"></i>User Instructions
              </Link>
            </li>

            <li>
              <Link to="/admin/instructions/break" className="text-info">
                <i className="fas fa-coffee px-3"></i>Break Instructions
              </Link>
            </li>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle bg-dark text-info ml-4 mt-4"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                S T E P S
              </button>
              <div
                className="dropdown-menu bg-secondary w-50"
                aria-labelledby="dropdownMenu2"
              >
                <Link
                  to="/admin/step1A"
                  className="dropdown-item text-info"
                  type="button"
                >
                  Step : 1A
                </Link>
                <Link
                  to="/admin/step1B"
                  className="dropdown-item text-info"
                  type="button"
                >
                  Step : 1B
                </Link>
                <Link
                  to="/admin/step2"
                  className="dropdown-item text-info"
                  type="button"
                >
                  Step : 2
                </Link>
                <Link
                  to="/admin/step3"
                  className="dropdown-item text-info"
                  type="button"
                >
                  Step : 3
                </Link>
              </div>
            </div>
            <br />

            <hr className="bg-info mr-4 ml-2 mb-0 mt-0" />

            <li className="text-light ml-5" style={{ cursor: "default" }}>
              {/* <i className="fas fa-gamepad px-3" ></i> */}
              GAME 2
            </li>

            <div className="pl-1">
              <li>
                <Link to="/admin/game2/upload" className="text-info">
                  <i className="fas fa-upload px-3"></i>Upload
                </Link>
              </li>

              <li>
                <Link to="/admin/game2/all-label" className="text-info">
                  <i className="fas fa-tags px-3"></i>Labels
                </Link>
              </li>
              <li>
                <Link to="/admin/game2/categories" className="text-info">
                  <i className="fas fa-sitemap px-3"></i>Categories
                </Link>
              </li>
            </div>
          </>
        }
      </ul>
    </div>
  );
}
export default SideNav;
