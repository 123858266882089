import Axios from "axios";
import React, { Component } from "react";
import UserHeader from "./UserHeader";
import { evaluateBreakPoint } from "../../utils";
import { SYNC_INTERVAL } from "../../constants";

class UserStep1B extends Component {
  state = {
    data: "",
    flashTime: "",
    nineXFlashTime: "",
    blankScreenFlashTime: "",
    iterations: 0,
    breakPoint: null,
    results: [],
    rights: 0,
    wrongs: 0,
    instructions: "",
    previousSession: null,
    sessionId: null,

    // Latency
    dispTime: "",
  };
  componentDidMount() {
    document.getElementById("nineXContainer").style.display = "none";
    document.getElementById("breakResumeButton").style.display = "none";
    document.getElementById("breakInstructions").style.display = "none";

    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/user", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user-access-token"),
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.props.history.push("/game1");
        } else {
          Axios.get(process.env.REACT_APP_BASE_URL + "/admin/steps/instructions", {
            params: { step: "1B" }
          }).then(result => {
            this.setState({
              instructions: result.data.instructions,
              breakPoint: result.data.breakPoint || null
            })
            document.getElementById("step1_instructions").innerHTML = result.data.instructions
            document.getElementById("breakInstructions").innerHTML = result.data.breakPointInstructions

          }).catch(error => {
            console.log(error);
          })

          Axios.get(process.env.REACT_APP_BASE_URL + "/users/game/session", {
            params: { gameType: "GAME_ONE" },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            },
          }).then(result => {
              this.setState({
                previousSession: result.data.lastSession,
                sessionId: result.data.lastSession?._id || null,
              }, () => {
                if(!this.state.previousSession) {
                  this.props.history.push("/game1");
                } else if (this.state.previousSession.status === "STEP_1B_COMPLETED") {
                  this.props.history.push("/step2");
                } else if (this.state.previousSession.status === "STEP_2_COMPLETED") {
                  this.props.history.push("/step3");
                }
              })
          }).catch(error => {
            console.log(error);
          })
        }
      })
      .catch((error) => console.log(error));
  }

  showBreakPoint = () => {
    document.getElementById("breakResumeButton").style.display = "block";
    document.getElementById("breakInstructions").style.display = "block";

    document.getElementById("wordContainer").style.display = "none";
    document.getElementById("buttons").style.display = "none";

  }
  resumeBreak = () => {
    document.getElementById("breakResumeButton").style.display = "none";
    document.getElementById("breakInstructions").style.display = "none";

    setTimeout(() => {
      this.startGame();
    }, this.state.blankScreenFlashTime);
  }


  handleSync = async (params = {}) => {
    const sum = this.state.results.reduce((accumulator, item) => accumulator + item.latency, 0)
    const averageLatency = sum / this.state.results.length
    await Axios.post(process.env.REACT_APP_BASE_URL + "/users/start/step3/save-results", {
      ...params,
      step1BMetadata: {
        data: this.state.data,
        flashTime: this.state.flashTime,
        nineXFlashTime: this.state.nineXFlashTime,
        blankScreenFlashTime: this.state.blankScreenFlashTime,
        iterations: this.state.iterations
      },
      sessionId: this.state.sessionId,
      step1BResults: {
        results: this.state.results,
        rights: this.state.rights,
        wrongs: this.state.wrongs,
        averageLatency
      }
    }, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("user-access-token"),
        }
    }).then((syncResponse) => {
      if(syncResponse?.data?.sessionId) {
          this.setState({
              sessionId: syncResponse?.data?.sessionId
          })
      }
    }).catch(error => {
        console.log(error);
    })
  }

  onStartClick = (e) => {
    var dt = new Date(Date.now());
    // console.log(dt); // Gives Tue Mar 22 2016 09:30:00 GMT+0530 (IST)

    dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
    // console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)

    var offset = -300; //Timezone offset for EST in minutes.
    var estDate = new Date(dt.getTime() + offset * 60 * 1000);

    estDate = estDate.toString().split(' ').slice(0, 5).join(' ');

    document.getElementById("step1_instructions").style.display = "none"
    document.getElementById("startButton").style.display = "none";

    if(this.state?.previousSession?.step1BMetadata?.data?.length) {
      this.setState({
        data: this.state.previousSession?.step1BMetadata.data,
        flashTime: this.state.previousSession?.step1BMetadata.flashTime,
        nineXFlashTime: this.state.previousSession?.step1BMetadata.nineXFlashTime,
        blankScreenFlashTime: this.state.previousSession?.step1BMetadata.blankScreenFlashTime,
        iterations: this.state.previousSession?.step1BMetadata.iterations,
        results: this.state.previousSession?.step1BResults.results,
        rights: this.state.previousSession?.step1BResults.rights,
        wrongs: this.state.previousSession?.step1BResults.wrongs,
      },() => {
        this.startGame();
      })
    } else {
      Axios.get(process.env.REACT_APP_BASE_URL + "/users/start/step1B/words", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-access-token"),
        },
      })
        .then((result) => {
          // console.log(result.data);
          this.setState({
            data: result.data.formattedData,
            flashTime: result.data.flashTime,
            nineXFlashTime: result.data.nineXFlashTime,
            blankScreenFlashTime: result.data.blankScreenFlashTime,
          });
          this.startGame();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  startGame() {
    this.setState({
      dispTime: Date.now()
    })
    document.getElementById("flashAlphabet").style.display = "none";
    document.getElementById("wordContainer").style.display = "none";
    document.getElementById("nineXContainer").style.display = "block";
    document.getElementById("buttons").style.display = "none";
    document.getElementById("flash").innerText = this.state.data[
      this.state.iterations
    ].flash;
    setTimeout(() => {
      document.getElementById("nineXContainer").style.display = "none";
      document.getElementById("flashAlphabet").style.display = "block";
      setTimeout(() => {
        document.getElementById("flashAlphabet").style.display = "none";
        document.getElementById("wordContainer").style.display = "block";
        document.getElementById("word").innerText = this.state.data[
          this.state.iterations
        ].word;
        document.getElementById("buttons").style.display = "block";
      }, this.state.flashTime);
    }, this.state.nineXFlashTime);
  }

  onAnswerClick = (e) => {

    const latency = (Date.now() - this.state.dispTime) / 1000;

    if (
      e.currentTarget.value === this.state.data[this.state.iterations].isWord
    ) {
      this.setState({
        results: [
          ...this.state.results,
          {
            flash1: this.state.data[
              this.state.iterations
            ].flash,
            word: this.state.data[
              this.state.iterations
            ].word,
            isAccurate: true,
            latency,
            iteration: this.state.iterations
          },
        ],
        rights: this.state.rights + 1,
        iterations: this.state.iterations + 1,
      }, () => {
        if (this.state.iterations === this.state.data.length) {
          document.getElementById("flashAlphabet").style.display = "none";
          document.getElementById("wordContainer").style.display = "none";
          document.getElementById("buttons").style.display = "none";
          document.getElementById("nextStep").style.display = "block";
          document.getElementById("step1_instructions").innerHTML = "<h5>Great work, keep it up!</h5>"
          document.getElementById("step1_instructions").style.display = "block"
          this.handleSync({
            status: "STEP_1B_COMPLETED"
          })
        } else {
          if(evaluateBreakPoint(this.state.iterations, this.state.breakPoint)) {
            this.showBreakPoint();
          } else {
            document.getElementById("wordContainer").style.display = "none";
            document.getElementById("buttons").style.display = "none";
            setTimeout(() => {
              this.startGame();
            }, this.state.blankScreenFlashTime);
          }
          if(evaluateBreakPoint(this.state.iterations, SYNC_INTERVAL)) {
            this.handleSync();
          }
        }
      });
    } else {
      this.setState({
        results: [
          ...this.state.results,
          {
            flash1: this.state.data[
              this.state.iterations
            ].flash,
            word: this.state.data[
              this.state.iterations
            ].word,
            isAccurate: false,
            latency,
            iteration: this.state.iterations
          },
        ],
        wrongs: this.state.wrongs + 1,
        iterations: this.state.iterations + 1,
      }, () => {
        if (this.state.iterations === this.state.data.length) {
          document.getElementById("flashAlphabet").style.display = "none";
          document.getElementById("wordContainer").style.display = "none";
          document.getElementById("buttons").style.display = "none";
          document.getElementById("nextStep").style.display = "block";
          document.getElementById("step1_instructions").innerHTML = "<h5>Great work, keep it up!</h5>"
          document.getElementById("step1_instructions").style.display = "block"
          this.handleSync({
            status: "STEP_1B_COMPLETED"
          })
        } else {
          if(evaluateBreakPoint(this.state.iterations, this.state.breakPoint)) {
            this.showBreakPoint();
          } else {
            document.getElementById("wordContainer").style.display = "none";
            document.getElementById("buttons").style.display = "none";
            setTimeout(() => {
              this.startGame();
            }, this.state.blankScreenFlashTime);
          }
          if(evaluateBreakPoint(this.state.iterations, SYNC_INTERVAL)) {
            this.handleSync();
          }
        }
      });
    }

  };

  onNextStepClick = async (e) => { 
    this.props.history.push("/step2",
      {
      }
    );
  };

  saveResults = e => {
    // console.log(this.state.latency);
    Axios.post(process.env.REACT_APP_BASE_URL + "/users/start/step1B/save-results",
      {
        rights: this.state.rights,
        wrongs: this.state.wrongs,
      }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user-access-token"),
      },
    }).then(result => {
      // console.log(result.data);
    }).catch(error => {
      console.log(error);
    })

  }
  render() {
    return (
      <div>
        <UserHeader />
        <div className="userStepOnePage container p-4">
          {/* <h1 className="display-4 text-center text-info">STEP 1</h1> */}
          <div className="text-center">
            <div className="stepOneInstructions text-center" id="step1_instructions">
              <p>{this.state.instructions}</p>
            </div>
            <div className="stepOneInstructions text-center" id="breakInstructions">
            </div>
            <button
              className="btn btn-info mt-4 py-2 px-3 mx-auto"
              id="breakResumeButton"
              onClick={this.resumeBreak.bind(this)}
            >
              <h4 className="mb-0">Resume!</h4>
            </button>
            { 
              this.state.instructions && 
              <button
                className="btn btn-info mt-4 py-2 px-3"
                id="startButton"
                onClick={this.onStartClick.bind(this)}
              >
                <h4 className="mb-0">Resume!</h4>
              </button>
            }
          </div>
          <div className="">
            <div className="mt-5" id="nineXContainer">
              <span id="nineXWord">XXXXXXXXX</span>
            </div>
            <div className="mt-5" id="wordContainer">
              <span id="word"></span>
            </div>
            <div className="mt-5" id="flashAlphabet">
              <p id="flash"></p>
            </div>
            <div id="buttons" className="py-5 mt-4 mb-3 mx-5">
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-danger px-5 "
                  value="n"
                  onClick={this.onAnswerClick.bind(this)}
                >
                  No
                </button>
                <button
                  className="btn btn-success px-5 "
                  value="y"
                  onClick={this.onAnswerClick.bind(this)}
                >
                  Yes
                </button>
              </div>
            </div>
            
          </div>
          <div id="nextStep" className="text-center">
            <h3># Correct : {this.state.rights}</h3>
            <h3># Incorrect : {this.state.wrongs}</h3>
            <button
              className="btn btn-info nextStepButton mt-5"
              onClick={this.onNextStepClick.bind(this)}
            >
              Next Step
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default UserStep1B;
