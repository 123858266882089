import Axios from "axios";
import React, { Component } from "react";
import Header from "../AdminComponents/Header";
import SideNav from "../AdminComponents/SideNav";

class RegisterUser extends Component {
  state = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "",
    version: 0,
    errors: "",
    successMsg: "",
  };

  componentDidMount() {
    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();

    Axios.post(process.env.REACT_APP_BASE_URL + "/users/signup", this.state, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((result) => {
        if (result.data.error) {
          this.setState({
            errors: result.data.error,
            successMsg: "",
          });
        }
        if (result.data.message) {
          this.setState({
            successMsg: result.data.message,
          });
          this.setState({
            userName: "",
            email: "",
            password: "",
            confirmPassword: "",
            errors: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <Header />
        <SideNav />

        <div className="userSignupPage">
          <div className="container">
            <form onSubmit={this.onSubmitHandler.bind(this)}>
              <div className="form-group">
                <label>Username <strong className="text-danger">*</strong></label>
                <input
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.userName}
                  className="form-control"
                  type="text"
                  name="userName"
                ></input>
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.email}
                  className="form-control"
                  type="email"
                  name="email"
                ></input>
              </div>

              <div className="form-group">
                <label>Password <strong className="text-danger">*</strong></label>
                <input
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.password}
                  className="form-control"
                  type="password"
                  name="password"
                ></input>
              </div>

              <div className="form-group">
                <label>Confirm Password <strong className="text-danger">*</strong></label>
                <input
                  onChange={this.onChangeHandler.bind(this)}
                  value={this.state.confirmPassword}
                  className="form-control"
                  type="password"
                  name="confirmPassword"
                ></input>
              </div>

              <div className="form-group ">
                <label className="">Select User Type <strong className="text-danger">*</strong></label>
                <select
                  name="userType"
                  className="form-control"
                  onChange={this.onChangeHandler.bind(this)}
                  defaultValue="default"
                >
                  <option value="default" disabled>
                    User Type
                  </option>
                  <option value="A">Active</option>
                  <option value="B">Sham</option>
                </select>
              </div>
              <div className="form-group ">
                <label className="">Select User Version <strong className="text-danger">*</strong></label>
                <select
                  name="version"
                  className="form-control"
                  onChange={this.onChangeHandler.bind(this)}
                  defaultValue="0"
                >
                  <option value="0" disabled>
                    Version
                  </option>
                  <option value="1">Version 1</option>
                  <option value="2">Version 2</option>
                </select>
              </div>
              <div className="form-group">
                <button className="btn btn-info float-right" type="submit">
                  Create
                </button>
              </div>

              <span className="text-danger text-center">
                {this.state.errors}
              </span>
              <span className="text-success text-center">
                {this.state.successMsg}
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterUser;
