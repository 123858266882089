import Axios from 'axios';
import React, { Component } from 'react'
import Header from '../Header'
import SideNav from '../SideNav'
import { v1 as uuid } from "uuid";


class Categories extends Component {
    state = {
        newCat: "",
        errors: "",
        success: "",
        allCategories: [],
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            if (result.data.error) {
                this.props.history.push("/admin")
            }
        }).catch(error => {
            console.log(error);
            this.props.history.push("/admin")

        })
    }


    onChangeHandler = (e) => {
        if (e.target.name === "newCat") {
            e.target.value = e.target.value.toUpperCase();
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onSubmit = e => {
        e.preventDefault()
        this.setState({
            errors: "",
            success: ""
        })

        Axios.post(process.env.REACT_APP_BASE_URL + "/admin/game2/new-category", this.state, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token")
            }
        })
            .then(result => {

                if (result.data.error) {
                    this.setState({
                        errors: result.data.error,
                        success: "",
                    })
                } if (result.data.message) {
                    this.setState({
                        success: result.data.message,
                        errors: "",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    deleteCategory = e => {
        e.persist()
        let x = e.currentTarget.value
        Axios.delete(process.env.REACT_APP_BASE_URL + "/admin/game2/delete-cat/" + e.currentTarget.value,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("admin-access-token")
                },

            }).then(result => {
                if (result.data.message) {
                    this.setState({
                        allCategories: this.state.allCategories.filter(cat => cat._id !== x)
                    })
                }

            }).catch(error => {
                console.log(error);
            })

    }


    render() {
        let displayCategories = (
            this.state.allCategories ? this.state.allCategories.map(cat => (
                <tr key={uuid()} className="">
                    <td className="p-1 px-4">
                        <span className="float-left">{cat.categoryName}</span>
                        <button className="btn btn-outline-danger py-0 float-right" value={cat._id}
                            onClick={this.deleteCategory}>
                            <i className="fa fa-trash-alt"></i></button>
                    </td>
                </tr>
            )) : ""
        )

        return (
            <div className="game2CategoriesPage">
                <Header />
                <SideNav />
                <div className="categories">

                    <h3 className="text-center">Categories</h3>
                    <div className="container w-25 mt-5">

                        <div className="text-center">
                            <span className="text-danger">{this.state.errors}</span>
                            <span className="text-success">{this.state.success}</span>
                        </div>

                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <input name="newCat" type="text" className="form-control" placeholder="Enter Category"
                                    onChange={this.onChangeHandler} value={this.state.category}></input>
                            </div>

                            <div className="form-group">
                                <button className="btn btn-info w-100">Add Category</button>
                            </div>
                        </form>

                    </div>
                    <div className="container mt-5">

                        <div className="text-center">
                            <button className="btn btn-outline-info" onClick={this.viewCategories}>View All Categories</button>
                        </div>

                        <div className="pt-5 allCategories w-50 m-auto" id="allCategoriesTable">
                            <div className="">
                                <table className="table table-hover table-light text-center">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>All Categories</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayCategories}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    viewCategories = e => {

        Axios.get(process.env.REACT_APP_BASE_URL + "/admin/game2/all-categories", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
            }
        }).then(result => {
            this.setState({
                allCategories: result.data.categories
            }, () => {
                document.getElementById("allCategoriesTable").style.visibility = "visible"
            })
        }).catch(error => {
            console.log(error);
        })

    }
}
export default Categories