import Axios from "axios";
import React, { Component } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

export default class AdminDashboard extends Component {
  componentDidMount() {

    Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/admin", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin-access-token"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          this.props.history.push("/admin");
        }
      })
      .catch((error) => {
        return console.log(error);
      });
  }
  render() {
    return (
      <div>
        <Header />
        <SideNav />
        <div className="text-center">
          <span className="lead">Welcome to Admin Dashboard</span>
        </div>
      </div>
    );
  }
}
