import Axios from 'axios'
import React, { Component } from 'react'
import Game2UserHeader from './Game2UserHeader'
import { prefetchImages } from '../../../utils';

class UserGame2 extends Component {
    state = {
        labels: [],
        labelCount: 0,
        playing: false,
        ready: false,
        top: [],
        left: [],
        right: [],
        upperLeft: [],
        upperRight: [],
        lowerLeft: [],
        isMobileOrTablet: false,
        lowerRight: [],
        previousSession: null,

        merged: [],
        flash: "",
        counter: 0,
        errors: "",

        game2StartTime: "",
        game2EndTime: "",
        stepStartTime: "",
        stepEndTime: "",
        blockNumber: 0,
        results: [],
        resultData: [],
        accuracy: [],
        sessionId: null,
        category: [],

        latencyStart: "",
        prevAccuracy: 1,

        buttonPressed: []
    }
    componentDidMount() {
        this.checkIsMobileOrTablet();
        setTimeout(()=> {
            if(this.state.isMobileOrTablet) {
                document.getElementById("_btn_start").classList.remove("tab-display");
                document.getElementById("_mobile_buttons").classList.remove("tab-display");
                document.getElementById("_instructions").classList.add("tab-display");
                document.getElementById("_left_label").classList.add("tab-display");
                document.getElementById("_right_label").classList.add("tab-display");
            }
        },0)
        document.getElementById("_mobile_buttons").style.opacity = 0;
        document.getElementById("_btn_start").style.opacity = 0;
        document.getElementById("resumeContainer").style.display = "none";
        document.getElementById("game2Container").style.display = "none";

        Axios.get(process.env.REACT_APP_BASE_URL + "/check-auth/user", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-access-token"),
            },
        }).then(result => {
            if (result.data.error) {
                this.props.history.push("/game2")
            }
            else {
                Axios.get(process.env.REACT_APP_BASE_URL + "/users/game/session", {
                    params: { 
                        gameType: "GAME_TWO",
                        isLoggingIn: true
                    },
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("user-access-token"),
                    },
                }).then(result => {
                        this.setState({
                            previousSession: result.data.lastSession
                        }, () => {
                            if(this.state.previousSession?.metadata?.labels?.length) {
                                document.getElementById("resumeContainer").style.display = "block";
                            } else {
                                document.getElementById("game2Container").style.display = "block";
                                this.initLabels();
                            }
                        })
                }).catch(error => {
                console.log(error);
                })
            }
        }).catch(error => {
            console.log(error);
        })

    }

    checkIsMobileOrTablet = () => {
        const target = navigator.userAgent||navigator.vendor||window.opera;
        if(
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || 
            navigator.platform === 'iPad' || 
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(target) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(target.substr(0,4))
        ) {
            this.setState({
                isMobileOrTablet: true
            })
        };
    }
 
    cacheImages = (merged) => {
        const set = new Set();
        for(let item of merged) {
            if(item.length >= 20){
                set.add(`${process.env.REACT_APP_STATIC_URL}/${item}`);
            }
        }
        prefetchImages([...set.values()])
      }

    initLabels = ( ) => {
        Axios.get(process.env.REACT_APP_BASE_URL + "/users/game2/all-labels", {
            headers: { Authorization: "Bearer " + localStorage.getItem("user-access-token") }
        }).then(result => {
            if (result.data.error) {
                if(this.state.isMobileOrTablet) {
                    document.getElementById("_btn_start").classList.remove("tab-display");
                    document.getElementById("_mobile_buttons").classList.remove("tab-display");
                }
                document.getElementById("_mobile_buttons").style.display = "none";
                document.getElementById("_btn_start").style.display = "none";
                this.setState({
                    errors: result.data.error
                })
            }
            if (result.data.labels) {
                this.setState({
                    labels: result.data.labels,
                    game2StartTime: this.getCurrentET()
                }, () => {
                    document.addEventListener("keydown", this.handleKeyDown)
                    this.showInstructions()
                })
            }
        }).catch(error => {
            console.log(error);
        })
    }

    onResume = () => {
        this.setState({
            ...this.state.previousSession.metadata,
            results: this.state.previousSession?.results || [],
            sessionId: this.state.previousSession?._id || null,
            game2StartTime: this.state.previousSession.startTime
        }, () => {
            document.getElementById("game2Container").style.display = "block";
            document.getElementById("resumeContainer").style.display = "none";

            document.addEventListener("keydown", this.handleKeyDown)
            this.showInstructions()
        })
    }

    onFreshStart= () => {
        document.getElementById("game2Container").style.display = "block";
        document.getElementById("resumeContainer").style.display = "none";
        this.initLabels();
        this.setState({
          previousSession: null,
        }, () => {
        })
    }

    handleSync = async (params = {}) => {
        const { sum, errorCount, totalIterations, accurateIterations } = this.state.results.reduce((accumulator, items) => {
            for(let item of items.data) {
                if(item.isAccurate) {
                    accumulator.sum += item.latency;
                    accumulator.accurateIterations++;
                } else {
                    accumulator.errorCount++
                }
            }
            accumulator.totalIterations += items.data.length
            return accumulator;
        }, {sum: 0, accurateIterations: 0, errorCount: 0, totalIterations: 0})
        const averageLatency = sum / totalIterations;
        const errorPercent = errorCount / accurateIterations * 100;

        await Axios.put(process.env.REACT_APP_BASE_URL + "/users/game2/save-game2-results", {
            ...params,
            metadata: {
                labels: this.state.labels,
                labelCount: this.state.labelCount,
                blockNumber: this.state.blockNumber,
            },
            startTime: this.state.game2StartTime,
            results: this.state.results,
            averageLatency,
            accurateCount: accurateIterations,
            errorCount,
            sessionId: this.state.sessionId,
            errorPercent,
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-access-token")
            }
        }).then((syncResponse) => {
            if(syncResponse?.data?.sessionId) {
                this.setState({
                    sessionId: syncResponse?.data?.sessionId
                })
            }
        }).catch(error => {
            console.log(error);
        })
      }

    showInstructions() {
        let instructionsDiv = document.getElementById("_instructions");
        instructionsDiv.innerHTML = this.state.labels[this.state.labelCount].instructions
        document.getElementById("_btn_start").style.opacity = 1;
        if(this.state.isMobileOrTablet) {
            document.getElementById("_instructions").classList.add("tab-display");
            document.getElementById("_btn_start").classList.add("tab-display");
        }
    }

    // e -> 69
    // i -> 73
    // space-bar -> 32
    handleKeyDown = (e) => {
        if (!this.state.playing) {
            if (e.keyCode === 32) {
                this.pressedSpace()
            }
        } else {

            if (e.keyCode === 69) {

                this.pressedE();

            }
            if (e.keyCode === 73) {
                this.pressedI();
            }

        }
    }
    pressedSpace() {
        if (!this.state.playing) {
            document.getElementById("_btn_start").style.opacity = 0;
            document.getElementById("_instructions").style.display = "none";
            if(this.state.isMobileOrTablet) {
                document.getElementById("_instructions").classList.add("tab-display");
                document.getElementById("_btn_start").classList.add("tab-display");
                document.getElementById("_mobile_buttons").classList.remove("tab-display");
            }
            this.setState({
                playing: true,
                ready: true,
                accuracy: [],
                category: [],
                buttonPressed: []
            }, () => {
                if(this.state.isMobileOrTablet) {
                    document.getElementById("_mobile_buttons").classList.add("tab-display");
                    document.getElementById("_btn_start").classList.remove("tab-display");
                    document.getElementById("_instructions").classList.remove("tab-display");
                }
                document.getElementById("_mobile_buttons").style.opacity = 1;
                document.getElementById("_mobile_buttons").style.visibility = "visible";
                this.collectData()
            })
        }
    }
    pressedE() {
        if (this.state.playing && this.state.ready) {
            this.setState({
                ready: false,
            })
            let found = this.state.left.find(item => item === this.state.merged[this.state.counter])
            if (found) {
                if (this.state.prevAccuracy === 1) {
                    this.setState({
                        accuracy: [...this.state.accuracy, 1]
                    })
                }
                let latency = (Date.now() - this.state.latencyStart) / 1000

                document.getElementById("_cross").style.display = "none"
                this.setState({
                    resultData: [
                        ...this.state.resultData,
                        {   
                            isAccurate: true,
                            latency,
                            iteration: this.state.counter,
                            buttonPressed: "e",
                            category: this.state.category[this.state.counter]
                        }
                    ],
                    counter: this.state.counter + 1,
                    prevAccuracy: 1,
                    buttonPressed: [...this.state.buttonPressed, "e"]
                    // ready: true
                }, () => {
                    this.flashWord()
                    this.setState({
                        ready: true
                    })
                })
            } else {
                this.setState({
                    accuracy: [...this.state.accuracy, 0],
                    resultData: [
                        ...this.state.resultData,
                        {   
                            isAccurate: false,
                            iteration: this.state.counter,
                            buttonPressed: "e",
                            category: this.state.category[this.state.counter]
                        }
                    ],
                    prevAccuracy: 0,
                    ready: true
                })
                document.getElementById("_cross").style.display = "block"
            }
        }
    }
    pressedI() {
        if (this.state.playing && this.state.ready) {
            this.setState({
                ready: false,
            })
            let found = this.state.right.find(item => item === this.state.merged[this.state.counter])
            if (found) {
                if (this.state.prevAccuracy === 1) {
                    this.setState({
                        accuracy: [...this.state.accuracy, 1]
                    })
                }
                let latency = (Date.now() - this.state.latencyStart) / 1000;

                document.getElementById("_cross").style.display = "none"
                this.setState({
                    counter: this.state.counter + 1,
                    resultData: [
                        ...this.state.resultData,
                        {   
                            isAccurate: true,
                            latency,
                            iteration: this.state.counter,
                            buttonPressed: "i",
                            category: this.state.category[this.state.counter]
                        }
                    ],
                    prevAccuracy: 1,
                    buttonPressed: [...this.state.buttonPressed, "i"]
                }, () => {
                    this.flashWord()
                    this.setState({
                        ready: true
                    })
                })
            } else {
                this.setState({
                    accuracy: [...this.state.accuracy, 0],
                    resultData: [
                        ...this.state.resultData,
                        {   
                            isAccurate: false,
                            iteration: this.state.counter,
                            buttonPressed: "i",
                            category: this.state.category[this.state.counter]
                        }
                    ],
                    prevAccuracy: 0,
                    ready: true
                })
                document.getElementById("_cross").style.display = "block"
            }
        }
    }
    collectData() {
        this.setState({
            errors: null
        })
        let leftCategories = this.state.labels[this.state.labelCount].leftCategories
        let rightCategories = this.state.labels[this.state.labelCount].rightCategories

        let leftTags = this.state.labels[this.state.labelCount].leftTags[0]
        let leftTags1 = this.state.labels[this.state.labelCount].leftTags[1]

        let rightTags = this.state.labels[this.state.labelCount].rightTags[0]
        let rightTags1 = this.state.labels[this.state.labelCount].rightTags[1]

        let numOfLabels = this.state.labels[this.state.labelCount].numOfLabels
        let iterations = this.state.labels[this.state.labelCount].iterations

        Axios.get(process.env.REACT_APP_BASE_URL + "/users/game2/get-label-data", {
            params: {
                leftCategories, rightCategories, leftTags, leftTags1, rightTags, rightTags1, numOfLabels, iterations
            },
            headers: { Authorization: "Bearer " + localStorage.getItem("user-access-token") }
        }).then(result => {
            if (result.data.error) {
                if(this.state.isMobileOrTablet) {
                    document.getElementById("_btn_start").classList.remove("tab-display");
                    document.getElementById("_mobile_buttons").classList.remove("tab-display");
                }
                document.getElementById("_mobile_buttons").style.display = "none";
                document.getElementById("_btn_start").style.display = "none";
                this.setState({
                    errors: result.data.error
                })
            } else {
                // console.log("Collected Data");
                // console.log(result.data);
                // Game2 Start Time
                let stepStartsAt = this.getCurrentET()
                if(result.data.merged?.length > 0 && result.data.merged[0].length >= 20) {
                    this.cacheImages(result.data.merged)
                }

                this.setState({
                    top: result.data.top,
                    left: result.data.left,
                    right: result.data.right,
                    upperLeft: result.data.upperLeft,
                    upperRight: result.data.upperRight,
                    lowerLeft: result.data.lowerLeft,
                    lowerRight: result.data.lowerRight,
                    merged: result.data.merged,
                    stepStartTime: stepStartsAt

                }, () => {
                    this.flashWord()
                    this.setState({
                        ready: true
                    })
                })
            }
        }).catch(error => {
            console.log(error);
        })

    }

    flashWord() {

        this.setState({
            latencyStart: Date.now(),
            ready: false

        }, () => {
            if (this.state.counter < this.state.merged.length) {

                let foundInUpperLeft = this.state.upperLeft.find(item => item === this.state.merged[this.state.counter])
                let foundInUpperRight = this.state.upperRight.find(item => item === this.state.merged[this.state.counter])
                let foundInLowerLeft = this.state.lowerLeft.find(item => item === this.state.merged[this.state.counter])
                let foundInLowerRight = this.state.lowerRight.find(item => item === this.state.merged[this.state.counter])
    
                if (foundInUpperLeft) {
                    this.setState({
                        category: [...this.state.category, this.state.labels[this.state.labelCount].leftCategories[0]]
                    })
                }
                if (foundInUpperRight) {
                    this.setState({
                        category: [...this.state.category, this.state.labels[this.state.labelCount].rightCategories[0]]
                    })
                }
                if (foundInLowerLeft) {
                    this.setState({
                        category: [...this.state.category, this.state.labels[this.state.labelCount].leftCategories[1]]
                    })
                }
                if (foundInLowerRight) {
                    this.setState({
                        category: [...this.state.category, this.state.labels[this.state.labelCount].rightCategories[1]]
                    })
                }
                let wordColor = foundInUpperLeft
                    ? this.state.labels[this.state.labelCount].leftColors[0]
                    : foundInUpperRight
                        ? this.state.labels[this.state.labelCount].rightColors[0]
                        : foundInLowerLeft
                            ? this.state.labels[this.state.labelCount].leftColors[1]
                            : foundInLowerRight
                                ? this.state.labels[this.state.labelCount].rightColors[1]
                                : "black"
    
                if (this.state.merged[this.state.counter].length < 20) {
    
                    let word = this.state.merged[this.state.counter]
    
                    document.getElementById("_flash_word").innerHTML = word
    
                    document.getElementById("_flash_word").style.color = wordColor
                    this.setState({
                        ready: true
                    })
                    // if (this.state.top.find(wrd => wrd === word)) {
                    //     document.getElementById("_flash_word").style.color = "green"
                    // } else {
                    //     document.getElementById("_flash_word").style.color = "black"
                    // }
    
                } else {
    
                    document.getElementById("_flash_word").innerHTML = `<img id="_flash_image"
                    src="${process.env.REACT_APP_STATIC_URL}/${this.state.merged[this.state.counter]}"
                     alt="img">`
    
    
                    document.getElementById("_flash_image").style.border = `4px solid ${wordColor}`
                }
    
                // this.state.merged[this.state.counter].length < 20
                //     ? this.state.merged[this.state.counter]
                //     : `<img id="_flash_image"
                //     src="${process.env.REACT_APP_STATIC_URL}/${this.state.merged[this.state.counter]}"
                //      alt="img">`
                document.getElementById("_flash_word").style.display = "block";
                document.addEventListener("keydown", this.handleKeyDown)
            } else {
                this.setState({
                    ready: false
                })
                let stepEndsAt = this.getCurrentET();
    
                this.setState({
                    stepEndTime: stepEndsAt,
                    labelCount: this.state.labelCount + 1,
                    blockNumber: this.state.blockNumber + 1
                }, () => {
    
                    console.log(this.state.accuracy.length)
    
                    while (this.state.accuracy.length !== this.state.resultData.length) {
                        console.log("___make me run___");
                        if (this.state.accuracy.length < this.state.resultData.length) {
                            let arr = this.state.accuracy
                            arr.push(0)
                            this.setState({
                                accuracy: arr
                            })
                        } else {
                            let arr = this.state.accuracy;
                            arr.pop()
                            this.setState({
                                accuracy: arr
                            })
                        }
                    }
                    const sum = this.state.resultData.reduce((accumulator, item) => item.isAccurate ? accumulator + item.latency : accumulator, 0);
                    const averageLatency = sum / this.state.resultData.length;
                    this.setState({
                        results: [...this.state.results,{
                            start: this.state.stepStartTime,
                            end: this.state.stepEndTime,
                            blockNumber: this.state.blockNumber,
                            data: [...this.state.resultData],
                            averageLatency
                        }],
                        resultData: []
                    }, () => {
                        this.nextLabel()
    
                    })
                })
            }
        })
    }

    nextLabel() {
        // console.log(this.state.accuracy);
        // console.log(this.state.latency);
        // console.log(this.state.category);
        if (this.state.labelCount < this.state.labels.length) {
            this.handleSync({
                status: `BLOCK_${this.state.blockNumber}_COMPLETED`,
            })
            if(this.state.isMobileOrTablet) {
                document.getElementById("_btn_start").classList.add("tab-display");
                document.getElementById("_mobile_buttons").classList.remove("tab-display");
                document.getElementById("_instructions").classList.add("tab-display");
            }
            document.getElementById("_mobile_buttons").style.visibility = "hidden";
            document.getElementById("_mobile_buttons").style.opacity = 0;
            console.log("Iterations over");
            this.setState({
                counter: 0,
                playing: false
            }, () => {
                document.getElementById("_flash_word").style.display = "none";
                document.getElementById("_cross").style.display = "none";

                let instructionDiv = document.getElementById("_instructions");
                instructionDiv.innerHTML = this.state.labels[this.state.labelCount].instructions;
                instructionDiv.style.display = "block"

                document.getElementById("_btn_start").style.opacity = 1;
            })

        } else {
            this.setState({
                blockNumber: 0,
                game2EndTime: this.getCurrentET()
            }, () => {
                this.handleSync({
                    status: "COMPLETED",
                    endTime: this.state.game2EndTime
                })
            })
            if(this.state.isMobileOrTablet) {
                document.getElementById("_btn_start").classList.remove("tab-display");
                document.getElementById("_mobile_buttons").classList.remove("tab-display");
                document.getElementById("_instructions").classList.add("tab-display");
            }
            document.getElementById("_mobile_buttons").style.display = "none";
            document.getElementById("_btn_start").style.display = "none";
            console.log("Game is over");
            document.getElementById("_cross").innerHTML = "";
            document.getElementById("_flash_word").style.display = "none"
            document.getElementById("_instructions").style.display = "block"
            document.getElementById("_instructions").innerHTML =
                `<div class="text-center m-auto">
                    <strong>All done—thank you!</strong> 
                    <p>You may now close this browser window</p>
                </div>`;
        }
    }

    render() {

        return (
            <div>
                <Game2UserHeader />

                <div className="container userGame2Page py-3" id="game2Container">
                    {/* <h1 className="display-4 text-center text-info">Game 2</h1> */}
                    <div className="text-center text-danger">
                        <strong>{this.state.errors ? `Error : ${this.state.errors}` : ""}</strong>
                    </div>
                    <div className="categories mx-4">
                        <div className="float-left text-center font-weight-bold" id="_left_label">
                            <span id="hint_left">Press "E" for</span>
                            <h3 className="font-weight-bold" style={{
                                color: `${this.state.labels[this.state.labelCount] !== undefined
                                    ? this.state.labels[this.state.labelCount].leftColors[0] : ""}`
                            }}>{
                                    this.state.labels[this.state.labelCount] !== undefined
                                        ? this.state.labels[this.state.labelCount].leftLabels[0]
                                        : ""
                                }</h3>
                            <h3 className="font-weight-bold" style={{
                                color: `${this.state.labels[this.state.labelCount] !== undefined
                                    ? this.state.labels[this.state.labelCount].leftColors[1] : ""}`
                            }}>{
                                    this.state.labels[this.state.labelCount] !== undefined
                                        ? this.state.labels[this.state.labelCount].leftLabels[1]
                                        : ""
                                }</h3>
                        </div>
                        <div className="float-right text-center font-weight-bold" id="_right_label">
                            <span id="hint_right">Press "I" for</span>
                            <h3 className="font-weight-bold" style={{
                                color: `${this.state.labels[this.state.labelCount] !== undefined
                                    ? this.state.labels[this.state.labelCount].rightColors[0] : ""} `
                            }}>{
                                    this.state.labels[this.state.labelCount] !== undefined
                                        ? this.state.labels[this.state.labelCount].rightLabels[0]
                                        : ""
                                }</h3>
                            <h3 className="font-weight-bold" style={{
                                color: `${this.state.labels[this.state.labelCount] !== undefined
                                    ? this.state.labels[this.state.labelCount].rightColors[1] : ""} `
                            }}>{
                                    this.state.labels[this.state.labelCount] !== undefined
                                        ? this.state.labels[this.state.labelCount].rightLabels[1]
                                        : ""
                                }</h3>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className="text-center instructions1 mt-5 mx-4" id="_instructions">

                    </div>
                    <button className="btn btn-success px-5 m-auto" id="_btn_start"
                        onClick={() => this.pressedSpace()}
                    >Start</button>
                    <h3 className="text-center font-weight-bold mt-b mb-2" id="_flash_word"> </h3>
                    <h1 className="text-center text-danger" id="_cross">
                        <i className="fa fa-times"></i>
                    </h1>

                    <div id="_mobile_buttons">
                        <button className="px-5 py-3"  id="_btn_e"
                            onClick={() => this.pressedE()}
                        ></button>
                        <button className="px-5 py-3" id="_btn_i"
                            onClick={() => this.pressedI()}
                        ></button>
                    </div>
                </div>
                <div className="container userGame2Page p-4" id="resumeContainer">
                    <div className="text-center instructions1 mt-5 mb-4 mx-4">
                        <h4>
                            Do you want to resume your previous session?
                        </h4>
                    </div>
                    <div className='text-center mt-4 mb-auto mx-4'>
                        
                        <button className="btn btn-info px-5 py-2 mr-2" 
                            onClick={() => this.onResume()}
                        >
                            Resume
                        </button>
                        <button className="btn btn-success  px-5 py-2"
                            onClick={() => this.onFreshStart()}
                        >
                            Start Fresh
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    getCurrentET() {
        var dt = new Date(Date.now());
        // console.log(dt); // Gives Tue Mar 22 2016 09:30:00 GMT+0530 (IST)
        dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
        // console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)
        var offset = -300; //Timezone offset for EST in minutes.
        var estDate = new Date(dt.getTime() + offset * 60 * 1000);
        estDate = estDate.toString().split(' ').slice(0, 5).join(' ');

        return estDate;
    }

}
export default UserGame2